app-cart {
	
	.dropZone[drop-active=true] {
		box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(0,102,94, 0.6)!important;
	}


	.cart-content{
		padding:10px;
		.ungrid-col {
				vertical-align:top;
				label.top-pad {
						padding-top:5px;
				}
				
		}
		table.cart-item-list{
			width:100%;
			td {
				border:1px solid #ddd
			}
			thead{
				//background-color:$brand-color;
				background-color:mix(white, $brand-color, 60)!important;
				

				
				color:black;
				th{
						//border-top:1px solid mix(white, $brand-color, 60);
						//
						//border-right:1px solid mix(white, $brand-color, 60);
						border-bottom: 1px solid $brand-color;
				}
				th.first-col {
						border-left:1px solid mix(white, $brand-color, 60);
						width:56px;
				}
				th.last-col {
						border-right:1px solid mix(white, $brand-color, 60);
				}
				tr {margin-bottom:3px;}
			}
			tbody {
				/*
				tr {
					//border-bottom:1px solid #ddd;
				}
				*/
				tr:nth-child(even){
					background-color:#eee
				}
				td {
						padding:3px;
						line-height:1.2em;
						//border-right:1px solid #ddd
				}
			}
			.gtotal{
					font-size:1.2em
			}

			.gsubtotal {
				font-size: 1.2em!important; font-weight:400!important;
			}

			.rpad {
				padding-right:10px;
				padding-left:10px;
			}

			.first-col{
					
					width:56px;
					//border-left:1px solid #ddd
			}
			.last-col {
					padding:3px;
					//border-right:1px solid #ddd
			}
			/*
			.first-row {
					//border-top:1px solid red;
			}
			*/
		}

		.cart-input{
			width:75px
		}
		.cart-detail-row{
				display:block
		}
		.color-menu-image {
				margin-right:4px;
		}

	}
	img.cart-thumb {
	width:50px;
	height:50px;
	margin-left:1px;
	margin-right:1px;
	border:1px solid #ddd;
	background-color:white;
	cursor:zoom-in;
	&:hover {
		box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba($brand-color, 0.6)!important;
	}
	}
	#cartprint{
	* {font-family:arial; font-size:10pt!important;}
	table {border-collapse:collapse}
	table.darkborder {border-collapse:collapse}
	table.darkborder td {border:1px solid black;text-align:center}
	table.darkborderl {border-collapse:collapse}
	table.darkborderl td {border:1px solid black;}
	table.nobottom td, tr.nobottom td {border-bottom:0px}
	table.notop td, tr.notop td {border-top:0px!important}
	td {vertical-align:top;padding:0;margin:0}
	.headerrow {border-bottom:1px solid black!important}
	tr.headerrow td {font-weight:bold;border-bottom:0px!important}
	h1 {margin:0;padding:0;font-size:20pt!important;font-weight:bold}
	.fixedwidth  {	font-family:courier;	margin-left:10px; font-weight:bold}
	table.noborder td {border:0px}
	table.fixedwidth td {	font-family:courier;	border:0; text-align:right;padding-left:30px}
	td.pad {padding:4px;}
	.lineitemdetail {padding-bottom:5px}
	}
	.attachment {
	display:inline-block;
	background-color:rgba(0, 0, 0, 0.075);
	border:1px solid rgba(0, 0, 0, 0.20);
	border-radius:5px;
	margin-bottom:5px;
	padding:3px 3px 3px 6px;
	vertical-align:middle;
	margin-right:10px;
	i {
		margin-right:0.25em;
	}
	button {
		margin-left:1em; 
	}
	animation-duration: 0.25s;
	animation-name: fade-in;
	}

	.attachmentContainer {
	padding-bottom:0;
	}
	.attachmentContainer:before,
	.attachmentContainer:after 
	{
	content: "";
	display: table;
	}

	.attachmentContainer:after 
	{
	clear: both;
	}

	.attachmentContainer 
	{
	zoom: 1; /* ie 6/7 */
	}

}