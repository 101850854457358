
body .toast-msg {
	animation-duration: 0.25s;
	animation-name: pop-in;
}


@-webkit-keyframes pop-in {
	0% { opacity: 0; -webkit-transform: scale(0.5); }
	100% { opacity: 1; -webkit-transform: scale(1); }
}
@-moz-keyframes pop-in {
	0% { opacity: 0; -moz-transform: scale(0.5); }
	100% { opacity: 1; -moz-transform: scale(1); }
}
@keyframes pop-in {
	0% { opacity: 0; transform: scale(0.5); }
	100% { opacity: 1; transform: scale(1); }
}
.toast-msg{
	display:inline-block;
	background-color:#ffffbf;
	box-shadow: 0 5px 15px rgba(0,0,0,.5);
	bottom:60px;
	font-size: 2em;
	padding:5px 10px;
	border:1px solid black;
	font-weight:bold;
	opacity:1;
	transition: opacity 0.5s ease;
	margin-bottom:5px;
}
#toast {
	position:absolute;
	height:60%;
	left:0;
	right:0;
	bottom:0;
	z-index:10000;
	width:100%;
	
	text-align:center;
	pointer-events: none;
}