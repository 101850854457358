

$base-line-height: 75px;
$loading-primary-color: $brand-color;//rgb(255,255,255);
$loading-secondary-color: rgba($loading-primary-color, 0.2);
$spin-duration: 1s;
$pulse-duration: 750ms;

@keyframes spin {
	0% {
	transform: rotate(0deg);
	}
	100% {
	transform: rotate(-360deg);
	}
}

@keyframes pulse {	
	50% { 
	background: $loading-primary-color;
	} 
}

.imageZoomMask{
	z-index:20000;
	background-color:rgba(0,0,0,.5);
	position:absolute;
	top:0;
	left:0;
	right:0;
	bottom:0;
  cursor:zoom-out;
}
.imageZoom{
  padding:50px;
  position:absolute;
	top:0;
	left:0;
	right:0;
	bottom:0;
}
.imageZoom img {
  background-color:white;
  box-shadow: 0px 0px 17px 5px rgba(0,0,0,0.75);

  width:auto;
  height:auto;
  //max-height:90%;
  //max-width:90%;
  max-height:95vmin;
  max-width:95vmin;
  display:block;
  position:absolute;
  left:0;
  right:0;
  bottom:0;
  top:0;
  margin:auto;
}

.loadingMask{
	z-index:20000;
	background-color:rgba(255,255,255,.5);
	position:absolute;
	top:0;
	left:0;
	right:0;
	bottom:0;
	opacity: 0.0;
	pointer-events: none;
	//transition-property: opacity;
  //transition-duration: 0.1s;
}

.active {
	opacity: 1.0;
	pointer-events: all;
}


.loading {
	border-radius: 50%;
	width: $base-line-height;
	height: $base-line-height;
	//border: .5rem solid $loading-secondary-color;
	border-top-color: $loading-primary-color;
	animation: spin $spin-duration infinite linear;

	margin: auto;
	position: absolute;
	top: 0; left: 0; bottom: 0; right: 0;

	&--double {
	border-style: double;
	border-width: .5rem;
	}
}



.loading-pulse {
	position: relative;
	width: ($base-line-height / 4);
	height: $base-line-height;
	background: $loading-secondary-color;
	animation: pulse $pulse-duration infinite;
	animation-delay: ($pulse-duration / 3);
	&:before, &:after {
	content: '';
	position: absolute;
	display: block;
	height: ($base-line-height / 1.5);
	width: ($base-line-height / 4);
	background: $loading-secondary-color;
	top: 50%;
	transform: translateY(-50%);
	animation: pulse $pulse-duration infinite;
	}
	&:before {
	left: -($base-line-height / 2);
	}
	&:after {
	left: ($base-line-height / 2);
	animation-delay: ($pulse-duration / 1.5);
	}
}



  // scaling... any units
  $width: 120px;

  .circle-loading {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: $width;
    height: $width;
  }

  .circular {
    animation: rotate 2s linear infinite;
    height: 100%;
    transform-origin: center center;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;

  }

  .path {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
    stroke-linecap: round;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes dash {
    0% {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -35px;
    }
    100% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -124px;
    }
  }

  @keyframes color {
    100%,
    0% {
      stroke: #00665E;
    }
  }
