app-home {
	.routecontent {

		width: 100%;

		background-clip: border-box;
		background-color: rgb(255, 255, 255);
		background-image: url(/assets/images/wp-light.jpg);
		background-origin: padding-box;
		background-position-x: 50%;
		background-position-y: 50%;
		background-repeat: no-repeat;
		background-size: cover;
		display: flex;
		flex-direction: column;
	}


	.some-page-wrapper {
		margin-top: 10px;
		margin-left: 20px;
		margin-right: 0px;
		margin-bottom: 0px;
		
	}
	
	.row {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		width: 100%;
		
	}

	.flexRow {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		width: 100%;
		justify-content: space-evenly;
	}
	
	.column {
		display: flex;
		flex-direction: column;
		flex-basis: 100%;
	}
	.flexColumn {
		display: flex;
		flex-direction: row;
    flex-basis: 50%;
    justify-content: space-around;
	}

	.column > div {
		padding: 10px;
	}
	.welcomeText {
		text-shadow: 3px 3px 7px rgba(150, 150, 150, 1);
	}
	
	@media screen and (min-width: 860px) {
		.column {
			flex: 1
		}
	
		.double-column {
			flex: 2
		}
	}
	.text-right {
		display: flex;
	}
	.text-right>* {
		margin-left: auto;
	}

	.announcmentsHeader {
		font-size: 1.2em;
		font-weight: bold;
		background-color: #00665E;
		color: white;
		padding: 6px; }
	
	.announcmentsBody {
		padding: 10px;
		background-color: white;
		border-top: 0;
		border-left: 2px solid #00665E;
		border-right: 2px solid #00665E;
		border-bottom: 2px solid #00665E; 
	}


	
.cat-tile {
  background-color: white;
  padding: 5px;
  margin: 10px;
  border: 1px solid black;
  box-shadow: 3px 4px 5px 0px rgba(0, 0, 0, 0.44);
  text-align: center;
  display: inline-block;
  text-decoration: none;
  color: black;
  border: 1px solid rgba(0,0,0,0.25);
  border-radius: 5px;
}

.cat-tile:hover {
  background-color: white; //mix(white, $brand-color, 60)!important;
  border: 1px solid rgba(0,0,0,1);
}

.cat-tile:hover, .cat-tile:focus, .cat-tile:active {
  text-decoration: none;
  color: black;
}

.cat-tile:active {
  position: relative;
  top: 3px;
  left: 3px;
  box-shadow: 0px 0px 0px 0px rgba(0,0,0,0.44);
}

.cat-tile-title {
  white-space: nowrap;
  font-weight: bold;
}

.cat-tile-img img {
  border: 1px solid rgba(0,0,0,0.25);
  width: 200px;
  height: 200px;
}

.shipNA {
  opacity: 0.33 !important;
  pointer-events: none !important;
}

.shipNA div.cat-tile-img:before {
  content: url('/assets/images/shipNA.png');
  position: absolute;
  padding-right: 0px;
  padding-top: 40px;
}

.home-col {
  //border: 1px solid red!important;
  display: inline-block;
}

@media only screen and (max-width: 980px) {
  .cat-tile-title {
    font-size: 0.8em;
    width: 150px;
  }

  .cat-tile-img img {
    width: 150px;
    height: 150px;
  }

  .home-col {
    display: inline;
  }

  .shipNA div.cat-tile-img:before {
    margin-top: 20px;
    content: url('/assets/images/shipNAsm.png');
  }
}

@media only screen and (max-width: 400px) {
  .cat-tile-title {
    font-size: 1em;
    width: 200px;
    height: 30px;
    line-height: 30px;
  }

  .cat-tile-img img {
    display: none;
  }
}

	
}