@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';

@import "./app/style/_buttons.scss";
@import "./app/style/_fonts.scss";
//@import "../style/_buttons.scss";
@import "./app/style/_variables.scss";
@import "./app/style/_loader.scss";
@import "./app/style/_toast.scss";


@import url('https://fonts.googleapis.com/css2?family=Fira+Sans+Condensed:ital,wght@0,400;0,700;1,400;1,700&family=Fira+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');

* {
    font-family: 'Fira Sans', Verdana, Arial, Helvetica, sans-serif !important;
}

html {
	background-color: #eee;
	
	margin:0;
	padding:0;
	overflow: hidden;
	position: fixed;
	top:0;
	left:0;
	right:0;
	bottom:0;
	display:flex;
	flex-direction: row; /* this makes columns */
}

body {
	font-family: "Helvetica Neue", "Arial", sans-serif !important;
	font-size: 14px;
	margin:0;
	padding:0;
	background-color: white;
	margin-left: auto;
	margin-right:auto;
	
	max-width: 1280px;
	box-shadow: 0 0 24px rgba(0, 0, 0, 0.15);
	display: flex;
	flex-grow: 1;
	flex-direction: column;  /* and this makes rows */
}
/*
// If you are updating an existing project based on an earlier version of Bootstrap, 
// you can use sass extend to apply the rules to the old class names:
.pull-right {
	@extend .float-right;
}
.pull-left {
	@extend .float-left;
}
*/
app-root {
	
	display: flex;
	flex-direction: column;
	flex-grow: 1;
}

router-outlet + * {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
}

header.appheader {
	display: flex;
	flex-direction: column;
	color:black;
	background-color: white;
	padding: 0;
}

div.approute {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
}

header, footer {
	display: flex;
	flex-direction: column;
	background-color: rgb(0, 102, 94);
	color: white;
	padding: 5px;
}


footer {
	box-shadow: 0 -4px 4px -3px rgb(0 0 0 / 75%);
}
div.routecontent {
	display: flex;
	flex-grow: 1;
	flex-shrink: 1;
	flex-basis: 1px;
	flex-direction: column;
	
	overflow: auto;
	height:100%;
	width:100%;
	padding: 10px;

	box-shadow: inset 0 8px 10px -10px black;
}

@media (min-width: 30em) {
	.ungrid-row { width: 100%; display: table; table-layout: fixed; }
	.ungrid-col { display: table-cell;}
}
.control-label {
	font-weight:bold;
	text-align:right;
	padding-right:3px;
}
.form-group {
  margin-bottom: 15px!important;
}
.form-control {
	border: 1px solid #ccc;
	background-color: #f8f8f8!important;
}
.flipH {
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  filter: FlipH;
  -ms-filter: "FlipH";
}

a, a.textlink {color:#00665E;text-decoration:underline}
button:active,
button:focus,
textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
select:focus,
input:focus,
.uneditable-input:focus {   
	border-color: rgba(0,102,94, 0.8) !important;
	box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(0,102,94, 0.6)!important;
	outline: 0 none !important;
}
textarea, input, select {
  background-color: #f8f8f8 !important; }


.account {
	/*
	position: fixed;
	top:0;
	left:0;
	right:0;
	bottom:0;
	*/
	background: url("/assets/images/wp.jpg") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
	background-color: white;

	p {
		background-color: transparent !important;
	}
}

.navbar{
	padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
  
}
.nav-tabs {
	margin-bottom: -1px!important;
}
.tab-content {
	margin-top: 0 !important;
}

 .nav-tabs.htabs {
	flex-direction: row;
	padding-left: 10px;	
}

.toolbar-spacer {
	flex: 1 1 auto;
}

a.title {
	color: white;
}

.btnAAD {
	background-color: darkblue;
	color: white;
}

.btnB2C {
	background-color: #00665e;
	color: white;
}

.btnLogout {
	background-color: red;
	color: white;
}

.dropdown-item.active {
	color: black;
	background-color: $brand-color-light;
}
.dropdown-item fa-icon,
button fa-icon {
	margin-right: 0.75em;
}
button.iconBtn fa-icon {
	margin-right: 0;
}
.btnRoundIcon {
	width: 2em;
	height:2em;
	padding: 0;
	border-radius: 1em;
	margin-left: 10px;
	fa-icon {
		margin-right: 0;
	}
}
.btnRoundIcon.btn-xs {
	width: 1.65em;
	height:1.65em;
	padding: 0;
	border-radius: .75em;
}
.eNav-logo {


	height: 2em;
}

.loader {
  pointer-events: none;
  height: 10px;
  width: 0%;
  border: 2px solid white;
  background-color: $brand-color-light;
  animation-duration: 1s;
  animation-name: load-in;
}

@keyframes load-in {
  0% {
    width: 0%;
  }

  100% {
    width: 100%;
    display: none;
  }
}

.menu-item-svg {
  height: 1em;
  width: 1em;
	margin-right: 0.5em;
	margin-left: -0.1em;
}
.dropdown-menu{
  box-shadow: 0 6px 12px rgba(0,0,0,.175);
}
.dropdown-toggle:hover, .dropdown-item:hover {
	background-color:#00665E;
	color:white;
}
.whiteBg {
	background-color: white;
}
.roundIcon{
	border-radius: 0.5em;
}
.dropdown-item-parent {
	
    display: block;
    width: 100%;
    padding: .25rem 1rem;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    text-decoration: none;
    white-space: nowrap;
    background-color: transparent;
    border: 0;

}

.dropdown-item-parent::after {
	display: inline;
	float: right;
	text-align: right;
	margin-top:.6em;
	margin-left: .255em;
	vertical-align: .255em;
	content: "";
	border-top: .3em solid transparent;
	border-right: 0;
	border-bottom: .3em solid transparent;
	border-left: .3em solid;
}

.dropdown-item-child.dropdown-menu.show {
	margin-left: -10px !important;
	margin-top: -5px !important;
}


a[target=_blank] {
	cursor: alias;
}
.btnSocial {
	font-size:2.8em;
	margin-right:0.1em;
	margin-bottom:-5px;
}

.social-buttons .btnSocial {
	text-decoration: none;
	display: inline-flex;
}

.facebookIcon {color:#3b5998}
.twitterIcon {color:#00acee}
.linkedInIcon {color:#0e76a8}
.youtubeIcon {color:#c4302b}
.pinterestIcon {color:#E60023}
.houzzIcon {color:#4DBC15}
.instagramIcon {color:#d92e7f}


.insta-icon {
	top:3px;
  position: relative;
  width: 36px;
  height: 36px;
  border-radius: 20%;
  background: radial-gradient(circle at 33% 100%, #fed373 4%, #f15245 30%, #d92e7f 62%, #9b36b7 85%, #515ecf)
}
.insta-icon:after,
.insta-icon:before {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 25px;
  height: 25px;
  border: 2px solid #fff;
  transform: translate(-50%, -50%);
  content: ''
}
.insta-icon:before {
  border-radius: 20%
}
.insta-icon:after {
  width: 11px;
  height: 11px;
  border-radius: 50%
}

.dropdown-item:hover fa-icon.facebookIcon {color:white}
.dropdown-item:hover fa-icon.twitterIcon {color:white}
.dropdown-item:hover fa-icon.linkedInIcon {color:white}

.modal-header {
	font-size: 15px!important;
	padding: 14px;
	line-height:15px;
	background-color: #00665E;
	color: white;
	border-radius: 0.75em 0.75em 0 0;
	h4{
		font-size: 18px;
		line-height: 18px;
		padding: 0;
		margin: 0;
	}
}

.modal-title fa-icon{ margin-right: 10px}

.modal-footer {
	font-size: 15px!important;
	padding: 14px;
	line-height:15px;
	background-color: #00665E;
	color: white;
	border-radius: 0 0 0.75em 0.75em;
	
	text-align: right;
}

.modal-content {
	font-size: 14px!important;
	box-shadow: 0 5px 15px rgba(0,0,0,.5);
	border:2px solid #00665E;
	border-radius: 1em;
	margin: -1px;
}

.dist-cols-parent {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
  //border: 1px solid red;
}
.dist-cols-child {
	display: flex;
	align-items: center;
	//border: 1px solid yellow;
}

footer a {
	color: white;
	&:hover {
		color: white;
	}
}

.unpad {
	margin:0;
	padding:0;
}
.closeBtn{
	border: 0;
	background-color: transparent;
	color: white;
	font-size: 2em;
	padding:0;
	fa-icon {
		margin:0;
	}
}


modal-container {
	background-color: rgba(0, 0, 0, 0.25)!important;
}
.scroll-Item {
	cursor:pointer;
	border-bottom: 1px solid #ccc;
	&:hover {
		background-color: rgba(0,102,94,.25);
	}
}
.selected {
	background-color: rgba(0,102,94,.5);
	&:hover {
		background-color: rgba(0,102,94,.5);
	}
}

.self-send.active {
	background-color: #99c2bf;
	color: #000;
}

.self-send {
	cursor: pointer;
	border-radius: 3px;
	padding: 2px;
	display: inline-block;
	margin: 1px;
	padding: 1px;
}
label input[type=checkbox] {
	margin-right: 7px;
	transform: scale(1.2);
}
.hlrows tr:hover {
	background-color: rgba(0,102,94,.5)!important;
}
.hlrows td {
	font-stretch: condensed;
	cursor: pointer;
}



.dialog-scroll-content {
	max-height: 300px;
	overflow-y:scroll;
	/*max-width:400px;*/
	background-color:white;
	border: 1px solid #cccccc;
}
.scroll-item{
	border:1px solid white;
	padding:2px;
	cursor:pointer;

		
	

}
.scroll-item:hover{
	border:1px solid $brand-color;
}
.dialog-scroll-content .scroll-item, .vscroll .scroll-item{
	background: -moz-linear-gradient(top,  rgba(255,255,255,0) 0%, rgba(242,242,242,1) 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(242,242,242,1) 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom,  rgba(255,255,255,0) 0%,rgba(242,242,242,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#f2f2f2',GradientType=0 ); /* IE6-9 */
  user-select: none; 
}
/*
.dialog-scroll-content .scroll-Item:nth-child(even) {
	 background-color: #eee;
}
	*/
.dialog-scroll-content .scroll-item.selected, .vscroll .scroll-item.selected {
	 color: black;
	background-color: lighten( $brand-color, 10% );
	border:1px solid $brand-color;
	/*
	 background-color: #00665E;
	 color: white;*/
}
.dialog-scroll-inline-header{
	background-color:#cccccc;font-weight:bold;
	padding:3px;
}
.scroll-item table td {
	padding:7px;
	 user-select: none; 
}


.dropdown-header {
  font-size: 1em;
  position: relative;
  left: -5px;
  top: -2px; }
  .dropdown-header i {
    position: relative;
    left: -7px;
    top: 0px; }
  .dropdown-header .pull-right {
    position: relative;
    left: 15px; }

.dropdown-submenu:hover > a {
  color: #fff;
  text-decoration: none;
  background-color: #00665E; }

.dropdown-submenu:hover > a:after {
  border-left-color: #fff; }

.menu-detail {
  white-space: nowrap;
  margin-top: 5px;
  margin-left: 15px;
  margin-right: 10px;
  line-height: 1.1em;
  padding: 5px; 
}
.btn-xs,
.btn-group-xs > .btn {
	padding: 1px 5px;
	font-size: 12px;
	line-height: 1.5;
	border-radius: 3px;
}



.waggle {
  animation-duration: .25s;
  animation-name: jello-horizontal;
  position: relative;
  top: 0;
  right: 0;
  background-color: mix(white, $brand-color, 60) !important;
  z-index: 100000;
}

@keyframes waggle {
  0% {
    transform: scale(1);
    top: 0;
    right: 0;
    background-color: mix(white, $brand-color, 60) !important;
  }

  49% {
    transform: scale(1.2);
    top: 0;
    right: 0;
    background-color: mix(white, $brand-color, 60) !important;
  }

  50% {
    transform: scale(1.2);
    top: 0;
    right: 0;
  }

  75% {
    background-color: white;
  }

  100% {
    transform: scale(1);
    top: 0;
    right: 0;
    background-color: mix(white, $brand-color, 60) !important;
  }
}


@keyframes jello-horizontal {
  0% {
    transform: scale3d(1.25, 0.75, 1);
    background-color: white;
  }

  30% {
    transform: scale3d(1.25, 0.75, 1);
  }

  40% {
    transform: scale3d(0.75, 1.25, 1);
  }

  50% {
    transform: scale3d(1.15, 0.85, 1);
  }

  65% {
    transform: scale3d(0.95, 1.05, 1);
  }

  75% {
    transform: scale3d(1.05, 0.95, 1);
  }

  100% {
    transform: scale3d(1, 1, 1);
    background-color: mix(white, $brand-color, 60) !important;
  }
}
.radio-btn fa-stack{

	vertical-align: middle;
}
/*
.radio-btn .fa-stack-2x{
	//opacity:0.5;
}
*/
.color-menu-image{
	position:relative;
	top:-1px;
	left:-10px;
	height: 20px;
	width: 20px;
	border: 1px solid #fff;
	box-shadow: 0 0 1px 0 rgb(0 0 0 / 75%);
}
.input-sm {
	height: 30px;
	padding: 5px 10px;
	font-size: 12px;
	line-height: 1.5;
	border-radius: 3px;
}
.disabled {
  pointer-events: none!important;;
  opacity: 0.3!important;;
}

.modal-body{
	max-height: calc(100vh - 180px);
	overflow-y: auto;
}
.modal-header {
	button {
		opacity: 1.0;
		font-size: 1.5em;
	}
	fa-icon {
		color: white;
	}
}
.media {
	display: flex;
	flex-direction: row;
	border-bottom: 1px solid #ccc;
	padding-bottom: 10px;
	&:last-child{
			border-bottom: 1px solid white;
	}
	margin-top:10px;
	&:margin-child{
		padding-top:0;
	}
	.media-body {
		margin-top: 15px;
		margin-left: 15px;
		margin-bottom: 0px;
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		
	}
}

.cardHead {

	display: flex;
	//flex-flow: row nowrap;
	width: 100%;
}
.cardCtrl {
	position: relative;
	left: -5px;

}
.cardDesc {

	display: flex;
	align-items: center;
	white-space: initial;
	text-overflow: ellipsis;
	line-height: 16px;
	font-size: 14px;
	font-weight: bold;
	&.topAligned {
		align-items:flex-start;
		font-size: 18px;
		line-height: 20px;
	}
}
/*
table.cardHead {
	margin-top: -2px;
}
td.cardCtrl {
	//vertical-align: top;
}
td.cardDesc {
//	position: relative;
	//top: -2px;
//	left: -2px;
	//vertical-align: middle;
	white-space: initial;
	text-overflow: ellipsis;
	line-height: 16px;
	font-size: 14px;
	font-weight: bold;
}
*/
.accessory-heading-row {
	
	text-decoration: underline;
	cursor: pointer;

	&:hover {
		background-color: #99c2bf
	}
	border-bottom: 1px solid #ccc;
	
	&:last-child{
			border-bottom: 1px solid white;
	}
	
	.color-menu-image {
		position: relative;
		left: -2px;
	}
	//td {
	//	white-space: nowrap;
	//}
}
accprice, .trimprice {
	display: flex;
  flex-direction: reverse;
	align-items: center;
	
  justify-content: right;
}
.btn-close{
	background-image:none!important;
}


.cart-content {
  padding: 10px; }
  .cart-content .ungrid-col {
    vertical-align: top; }
    .cart-content .ungrid-col label.top-pad {
      padding-top: 5px; }
  .cart-content table.cart-item-list {
    width: 100%; }
    .cart-content table.cart-item-list td {
      border: 1px solid #ddd; }
    .cart-content table.cart-item-list thead {
      background-color: #99c2bf !important;
      color: black; }
      .cart-content table.cart-item-list thead th {
        border-bottom: 1px solid #00665E; }
      .cart-content table.cart-item-list thead th.first-col {
        border-left: 1px solid #99c2bf;
        width: 56px; }
      .cart-content table.cart-item-list thead th.last-col {
        border-right: 1px solid #99c2bf; }
      .cart-content table.cart-item-list thead tr {
        margin-bottom: 3px; }
    .cart-content table.cart-item-list tbody tr:nth-child(even) {
      background-color: #eee; }
    .cart-content table.cart-item-list tbody td {
      padding: 3px;
      line-height: 1.2em; }
    .cart-content table.cart-item-list .gtotal {
      font-size: 1.2em; }
    .cart-content table.cart-item-list .gsubtotal {
      font-size: 1.2em !important;
      font-weight: 400 !important; }
    .cart-content table.cart-item-list .rpad {
      padding-right: 10px;
      padding-left: 10px; }
    .cart-content table.cart-item-list .first-col {
      width: 56px; }
    .cart-content table.cart-item-list .last-col {
      padding: 3px; }
  .cart-content .cart-input {
    width: 75px; }
  .cart-content .cart-detail-row {
    display: block; }
  .cart-content .color-menu-image {
    margin-right: 4px;
		left:0;
	 }

.smallStack {
	height: 1.4em;
	width: 1.4em;
	left: -.2em;
}
img.cart-thumb {
  width: 50px;
  height: 50px;
  margin-left: 1px;
  margin-right: 1px;
  border: 1px solid #ddd;
  background-color: white;
  cursor: zoom-in; }
  img.cart-thumb:hover {
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(0, 102, 94, 0.6) !important; }



		.ng-tooltip {
			outline: none; }
		
		.ng-tooltip strong {
			line-height: 30px; }
		
		.ng-tooltip:hover {
			text-decoration: none; }
		
		.ng-tooltip span {
			z-index: 10;
			display: none;
			padding: 14px 20px;
			margin-top: -30px;
			margin-left: 28px;
			width: 180px;
			line-height: 16px; }
		
		.ng-tooltip:hover span {
			display: inline;
			position: absolute;
			color: #111;
			border: 1px solid #DCA;
			background: #fffAF0; }
		
		.callout {
			z-index: 20;
			position: absolute;
			top: 30px;
			border: 0;
			left: -12px; }


#cartprint * {
  font-family: arial;
  font-size: 10pt !important; }

#cartprint table {
  border-collapse: collapse; }

#cartprint table.darkborder {
  border-collapse: collapse; }

#cartprint table.darkborder td {
  border: 1px solid black;
  text-align: center; }

#cartprint table.darkborderl {
  border-collapse: collapse; }

#cartprint table.darkborderl td {
  border: 1px solid black; }

#cartprint table.nobottom td, #cartprint tr.nobottom td {
  border-bottom: 0px; }

#cartprint table.notop td, #cartprint tr.notop td {
  border-top: 0px !important; }

#cartprint td {
  vertical-align: top;
  padding: 0;
  margin: 0; }

#cartprint .headerrow {
  border-bottom: 1px solid black !important; }

#cartprint tr.headerrow td {
  font-weight: bold;
  border-bottom: 0px !important; }

#cartprint h1 {
  margin: 0;
  padding: 0;
  font-size: 20pt !important;
  font-weight: bold; }

#cartprint .fixedwidth {
  font-family: courier;
  margin-left: 10px;
  font-weight: bold; }

#cartprint table.noborder td {
  border: 0px; }

#cartprint table.fixedwidth td {
  font-family: courier;
  border: 0;
  text-align: right;
  padding-left: 30px; }

#cartprint td.pad {
  padding: 4px; }

#cartprint .lineitemdetail {
  padding-bottom: 5px; }

.attachment {
  display: inline-block;
  background-color: rgba(0, 0, 0, 0.075);
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  margin-bottom: 5px;
  padding: 3px 3px 3px 6px;
  vertical-align: middle;
  margin-right: 10px;
  animation-duration: 0.25s;
  animation-name: fade-in; }
  .attachment i {
    margin-right: 0.25em; }
  .attachment button {
    margin-left: 1em; }

.attachmentContainer {
  padding-bottom: 0; }

.attachmentContainer:before,
.attachmentContainer:after {
  content: "";
  display: table; }

.attachmentContainer:after {
  clear: both; }

.attachmentContainer {
  zoom: 1;
  /* ie 6/7 */ }


@keyframes pulse {
    0% { opacity: 0.5; }
    50% { opacity: 1; }
    100% { opacity: 0.5; }
}

.pulse {
    animation: pulse 1s infinite ease-in-out;
}


@keyframes pulseErrorText {
	0% { color: rgba(255, 0, 0, 0.5); }
	50% { color: rgba(255, 0, 0, 1); }
	100% { color: rgba(255, 0, 0, 0.5); }
}
.pulseErrorText {
	color: red;
	animation: pulseErrorText 1s infinite ease-in-out;
}

@keyframes pulseErrorTextBk {
	0% { background: rgba(255, 0, 0, 0.5); }
	50% { background: rgba(255, 0, 0, 1); }
	100% { background: rgba(255, 0, 0, 0.5); }
}
.pulseErrorTextBk {
	color: white;
	animation: pulseErrorText 1s infinite ease-in-out;
}

.flexBtn{
	display: flex;
	align-items: center;
}

.angleError {
	color: red;
	animation: pulseErrorText 1s infinite ease-in-out;
}
.angleError::selection {
  color: white;
	background: rgba(255, 0, 0, 1);
	animation: pulseErrorTextBk 1s infinite ease-in-out;
}

$filter-width: 280px;
.filter-toggle-btn {
  display:none;
}
.filterMenuBody {
	display:inline-block;
}
@media only screen and (max-width: 768px) {
  .filter-toggle-btn {
    display:inline-block;
  }
	.filterMenuBody {
		display:none;
	}
}

.lastName {
  display: none;
}

@media (min-width: 768px) {
  .lastName {
    display: inline-block;
  }
}

@media only screen and (max-width: 410px) {
  .social-buttons {
    display: none;
  }
}

.filterMenu {
	display: block;
	width: 290px;
	padding-left: 10px;
	padding-right: 10px;
}
/*
.modal-dialog {
  position: relative;
  display: table;
  overflow: auto;
  width: auto;
  min-width: 300px;
}
.modal-body { // Restrict Modal width to 90%
  overflow-x: auto !important;
  max-width: 90vw !important;
}*/

ul.nav-tabs-bottom {
  
  border-bottom: 0;
  margin-bottom: 5px;
	padding-left: 10px;
	border-top:1px solid #dfdfdf;
  li {
		margin-top:-1px;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
    border-bottom-left-radius: 3px !important;
    border-bottom-right-radius: 3px !important;
    border-bottom: 1px;
		
    a {
			
      border-bottom-width: 1px !important;
      border-top: 0 !important;
      border-bottom: 1px;
      border-bottom-color: rgb(221, 221, 221) !important;
      border-top-left-radius: 0px !important;
      border-top-right-radius: 0px !important;
      border-bottom-left-radius: 3px !important;
      border-bottom-right-radius: 3px !important;
    }
		
  }
}
.pageTitle {
	font-weight: bold;
	font-size: 1.5em;
	fa-icon {
		font-weight: normal;
		margin-right: 10px;
	}
}

.hemIcon {
  width: 20px;
  position: relative;
  left: -5px;
}

.input-inch {
  background-image: url(/assets/images/inches.png);
  background-position: right top;
  background-repeat: no-repeat;
}

.input-degree {
  background-image: url(/assets/images/degrees.png);
  background-position: right top;
  background-repeat: no-repeat;
}
.trim-edit-layout td {
  vertical-align: top; }

.trimformlabel {
  display: inline-block;
  border: 0;
  width: 89px;
  text-align: right; }

.labelFlipper {
  border: 1px solid #ddd;
  background-color: #eee;
  border-radius: 4px;
  cursor: pointer;
  padding-left: 6px;
  padding-right: 6px;
  font-weight: normal;
  vertical-align: middle; }
  .labelFlipper:hover {
    background-color: #ccc; }

.flangeForm {
  background-color: #fbfbfb;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 10px;
  white-space: normal !important; }
  .flangeForm .form-group {
    display: inline-block !important;
    margin: 0px;
    height: 34px; }
    .flangeForm .form-group .input-group-addon {
      text-align: right !important;
      font-weight: bold; }
    .flangeForm .form-group .input-group-addon.lbl {
      text-align: left !important; }
    .flangeForm .form-group .input-group {
      height: 34px;
      width: 180px;
      margin: 0px; }
    .flangeForm .form-group input, .flangeForm .form-group select {
      text-align-last: center;
      text-align: center;
      padding-left: 0px;
      padding-right: 0px; }
/*
.lineremovebtn {
  display: none; 
}

.linelabelParent .linelabel {
  display: inline-block; }

.linelabelParent .lineremove {
  display: none; }

.linelabelParent:hover .linelabel {
  display: none; }

.linelabelParent:hover .lineremove {
  display: inline-block; }

.linelabelRoot .linelabel {
  margin-left: 12px; }

.linelabelRoot .lineremove {
  margin-left: 5px; }
*/

.lineremovebtn {
	display: none; 
  position: relative;
	left:-16px;
}
.linelabelParent:hover .lineremovebtn {
	display: inline-block;
}
input[type='number'] {
	text-align: center;
	padding-left:0;
	padding-right:0;
}
input[type='number']::-webkit-inner-spin-button {
	position: absolute;
	width: 12.5%;
	height: 100%;
	top: 0;
	right: 0;
	opacity: 0.5;
}
.input-group-addon.lbl.no-padding {
  padding: 0;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-top: 0;
  width: 35px; }
.scrolldrop { 
	max-height: 350px; 
	overflow-y: auto; 
	overflow-x: hidden; 
	min-width: 100% !important; 
	background-attachment: local, local, scroll, scroll; 
}
.svgIcon {
	width: 16px;
	height: 16px;
}
.nav-link {
	cursor:pointer;
	padding-left: 15px;
	padding-right: 15px;
}
.nav-tabs  .nav-link fa-icon{
	margin-right:10px;
}
.flangeList {
	display: flex;
  flex-flow: row wrap;

  max-width: 400px;
  flex-direction: row;
	.form-group{
		margin-right:5px;
	}
}
.flangeList.reverseRow {
	.form-group:nth-child(1) { order: 3; }
	.form-group:nth-child(2) { order: 2; }
	.form-group:nth-child(3) { order: 1; }
}
.flangelabel {
	width:180px;
	display: block;
	font-weight: bold;
	.float-start {
		font-weight: normal;
	}
}
.lockVertIcon {
	color: #ccc;
	position: relative;
	left: -12px;
	top: -10px;
	margin-right: -15px;
}

.modal.fade {
	transition: none !important;
}
.modal-header.error, .modal-footer.error {
	background-color: #6a220e;
	color:white;
}

#errorReportFrame {
  width: 400px;
  height: 300px;
	display: inline-block;
  float: right; 
}
  #errorReportFrame textarea {
    width: 100%;
    height: 6em;
    background-color: #f7f7f7;
    border-radius: 5px;
    margin: 10px 0px;
    padding: 5px; }
  #errorReportFrame button {
    font-size: 1.2em;
    background-color: #066;
    color: white;
    font-weight: bold;
    border-radius: 5px;
    border: 0;
    height: 2em;
    padding: 5px 10px;
    cursor: pointer; }
  #errorReportFrame .border {
    border-radius: 10px;
    border: 2px solid #066;
    padding: 10px;
    background-color: #ffc; }
  #errorReportFrame .rightAlign {
    text-align: right; }
  #errorReportFrame p.title {
    font-weight: bold;
    font-size: 1.2em;
    margin-bottom: 5px;
    width: 100%;
    text-align: center; }
  #errorReportFrame #formSubmitted {
    margin-top: 50px;
    margin-bottom: 50px; }
  #errorReportFrame #submitBtn {
    width: 100%; }

#errorDisplay::before,
#errorDisplay::after,
#errorDisplay *::before,
#errorDisplay *::after {
  all: unset; }

.errorJson {
  color: red;
  word-wrap: break-word;
  white-space: pre-wrap!important;
  font-family: Menlo,Monaco,Consolas,Courier New,monospace!important; 
}
.errorModal .modal-content {
	border: 2px solid #6a220e;
}


.paymentTermsInstructions, .orderMessage {
  margin:10px;
  padding:10px;
  border:1px solid #B68900;
  background-color: #ffc;
  font-size:1.2em;
  border-radius:5px;
  overflow: initial;
}


.rrow {
	display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
	//grid-template-columns: 1fr 1fr 1fr;
	//grid-template-columns: minmax(400px, 1fr) minmax(400px, 1fr) 1fr;
  grid-gap: 1rem;
}
.trimLeftCol {
	width: 400px;
	display: inline-block;
	margin-right: 10px;
	vertical-align:top; 
}
.trimMidCol {
	width: 400px;
	display: inline-block;
	vertical-align:top; 
}
.trimRightCol {
	display: block;
	margin-left:auto;
	float: right;
	text-align: right;
}


#updatingVersion {
  position: fixed;
  z-index: 10000;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 10000;
  background-color: rgba(0, 0, 0, 0.75);
  text-align: center;

  padding-top: 25%;

  img {
    height: 150px;
    animation: spinFast 1.5s infinite ease-in;
  }

  font-size: 25px;
  line-height: 30px;
  font-weight:bold;
  color:white;
}

.vertical-center {
  display: flex;
  justify-content: center;
  align-items: center;

}

@keyframes spinFast {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(1080deg);
  }
}
	//.dropdown-item { white-space: normal; }


.hovered, .hovered .file-input {
	box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(0,102,94, 0.6)!important;
}
.text-right {
	text-align: right
}

.widetip {
	margin: 5px;
	text-align: left;
	img {
		margin-top: 10px;
	}
}
.helpTip.show {
	opacity: 1.0;
}
.helpTip.bs-tooltip-bottom .tooltip-arrow::before {
	border-bottom-color: #00665E  !important;
}
.helpTip .tooltip-inner {
	opacity: 1.0;
	background-color: white;
	color: black;
	font-size: 125%;
	
	max-width: fit-content;
	padding: 20px;
	border: 2px solid #00665E;
	animation: pulseError;
}
.helpTip.bs-tooltip-end .tooltip-arrow::before {
	border-right-color: #00665E;
}
.helpTip.bs-tooltip-start .tooltip-arrow::before {
	border-left-color: #00665E;
}
.helpTip.bs-tooltip-top .tooltip-arrow::before {
	border-top-color: #00665E;
}
.helpTip.bs-tooltip-bottom .tooltip-arrow::before {
	border-bottom-color: #00665E;
}
.helpTip.bs-tooltip-bottom .tooltip-arrow::before {
	border-bottom-color: #00665E;
}

.helpTip.angleError .tooltip-inner {
	background-color: #ffc;
	color: red;
	animation: pulseErrorText 1s infinite ease-in-out;
	font-size: 0.9em;
	white-space: nowrap;;
	
	max-width: fit-content;
	padding: 5px;
	border: 2px solid red;
	button {
		font-size: 125%;
		line-height: 1em;
		margin-top:4px;
		padding: 5px;
	}
}
.helpTip.angleError.bs-tooltip-end .tooltip-arrow::before {
	border-right-color: red;
}
.helpTip.angleError.bs-tooltip-start .tooltip-arrow::before {
	border-left-color: red;
}
.helpTip.angleError.bs-tooltip-top .tooltip-arrow::before {
	border-top-color: red;
}
.helpTip.angleError.bs-tooltip-bottom .tooltip-arrow::before {
	border-bottom-color: red;
}
.helpTip.angleError.bs-tooltip-bottom .tooltip-arrow::before {
	border-bottom-color: red;
}



@media screen {


  #cartprint {
    display: none;
  }
}

@media print {

	html {
		background-color: transparent;
		
		margin:0;
		padding:0;
		overflow: auto;
		position: inherit;
		display:block;
		
	}
	body {
		overflow: hidden;
		background-color: transparent;
		box-shadow: none;
	}
	
  #cartprint {
    display: block;
  }

	table {border-collapse:collapse}
	table.darkborder {border-collapse:collapse}
	table.darkborder td {border:1px solid black;text-align:center}
	table.darkborderl {border-collapse:collapse}
	table.darkborderl td {border:1px solid black;}
	table.nobottom td, tr.nobottom td {border-bottom:0px}
	table.notop td, tr.notop td {border-top:0px!important}
	td {vertical-align:top;padding:0;margin:0}
	.headerrow {border-bottom:1px solid black!important}
	tr.headerrow td {font-weight:bold;border-bottom:0px!important}
	h1 {margin:0;padding:0;font-size:20pt!important;font-weight:bold}
	.fixedwidth  {	font-family:courier;	margin-left:10px; font-weight:bold}
	table.noborder td {border:0px}
	table.fixedwidth td {	font-family:courier;	border:0; text-align:right;padding-left:30px}
	td.pad {padding:4px;}
	.lineitemdetail {padding-bottom:5px}

  .approute, .appheader {
    display: none!important;
  }

}

@page {
  margin: 0.75cm;
}

#panel-color-dropdown {
	max-height: 35vh;
	overflow-y: auto;
}

.list-group-item-header {
	color:white;
	background-color: transparent;
}

//component styles are loaded here because:
// 1. HMR reloads the entire application if the component references it via styleUrls (https://medium.com/the-crazy-coder/watch-out-when-using-hmr-with-angular-b28a4dc7e19a)
// 2. If it's at the top of styles.scss, everything below that overrides the component styles
@import "./app/home/home.component.scss";

@import "./app/components/manage-users/manage-users.component.scss";
@import "./app/components/panels/panels.component.scss";
@import "./app/components/login/login.component.scss";
@import "./app/components/tabset/tabset.component.scss";
@import "./app/components/configure/configure.component.scss";
@import "./app/components/panel/panel.component.scss";
@import "./app/components/accessories/accessories.component.scss";
@import "./app/components/cart/cart.component.scss";
@import "./app/components/trims/trims.component.scss";
@import "./app/components/orders/orders.component.scss";
