/* site variables */
$app-max-width: 1280px;
$brand-color: #00665E;
$brand-color-light: mix(white, $brand-color, 60);
$warning-color: #6a220e;
$brand-secondary: rgb(240,234,191);
$btn-close-color: white;

::selection {
  color:white;
  background: $brand-color
  //color:black;
  //background: yellow;
}
::-moz-selection {
  color:white;
  background: $brand-color
  //color:black;
  //background: yellow;
}


svg text::selection { background: none; }