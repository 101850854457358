/*
// not really needed, because font-stretch gets the job done 
@font-face {
	font-family: 'Arial';
	src: url('/assets/fonts/Arial-Bold.woff2') format('woff2'),
			url('/assets/fonts/Arial-Bold.woff') format('woff');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Arial Narrow';
	src: url('/assets/fonts/ArialNarrow-Bold.woff2') format('woff2'),
			url('/assets/fonts/ArialNarrow-Bold.woff') format('woff');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Arial Narrow';
	src: url('/assets/fonts/ArialNarrow-BoldItalic.woff2') format('woff2'),
			url('/assets/fonts/ArialNarrow-BoldItalic.woff') format('woff');
	font-weight: bold;
	font-style: italic;
}

@font-face {
	font-family: 'Arial';
	src: url('/assets/fonts/Arial-Italic.woff2') format('woff2'),
			url('/assets/fonts/Arial-Italic.woff') format('woff');
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: 'Arial';
	src: url('/assets/fonts/Arial-Black.woff2') format('woff2'),
			url('/assets/fonts/Arial-Black.woff') format('woff');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'Arial Narrow';
	src: url('/assets/fonts/ArialNarrow-Italic.woff2') format('woff2'),
			url('/assets/fonts/ArialNarrow-Italic.woff') format('woff');
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: 'Arial Narrow';
	src: url('/assets/fonts/ArialNarrow.woff2') format('woff2'),
			url('/assets/fonts/ArialNarrow.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Arial';
	src: url('/assets/fonts/Arial-BoldItalic.woff2') format('woff2'),
			url('/assets/fonts/Arial-BoldItalic.woff') format('woff');
	font-weight: bold;
	font-style: italic;
}

@font-face {
	font-family: 'Arial';
	src: url('/assets/fonts/Arial.woff2') format('woff2'),
			url('/assets/fonts/Arial.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}
*/