@import "./_variables.scss";

.btn-default {
  color: #333;
  background-color: #fff;
  border-color: #ccc;
  &:active {
    color: black;
  }
}

.btn-primary {
	background: $brand-color!important;
	color: #ffffff;
  border-width:0;
  &:hover{
    background: mix(white, $brand-color, 30)!important;
	  color: #ffffff;
    border-width:0;
  }
}

.btn-secondary {
	background:mix(white, $brand-color, 60)!important;
	color: #000000;
  &:hover{
    background:mix(white, $brand-color, 75)!important;
	  color: #000000;
  }
  &:active, &:focus {
    color: black !important;
  }
}


.btn-tertiary {
	background: rgb(15, 91, 153)!important;
	color: #ffffff;
  border-width:0;
  &:hover{
    background: mix(white, rgb(15, 91, 153), 30)!important;
	  color: #ffffff;
    border-width:0;
  }
}

.btn-gradient{
  background-image: linear-gradient(rgba(255, 255, 255, 0) 0%, rgb(200, 200, 200) 100%);
  //box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
}

.btn-warning-secondary {
	background: #c2999c!important;
	color: #000000;
  &:hover{
	background: #ceadb0!important;
	color: #000000;
  }
}
.btn-email span.caret {
	position: absolute;
	right: 6px;
	top: 15px;
	left:auto;
} 
button i {
	position:relative;
	left:-5px;
	top:2px;
	font-size:1.3em!important
}
.btn:focus,.btn:active {
	 outline: none !important;
}
button:focus {outline:0;}
.btn.disabled, .btn[disabled], fieldset[disabled] .btn {
	cursor: not-allowed;
	filter: alpha(opacity=65);
	-webkit-box-shadow: none;
	box-shadow: none;
	opacity: .5;
}
.btn-warning-dark{
  background-color:$warning-color;
  color:white;
  &:focus{
    color:white;
  }
  &:hover{
    background:mix(white, $warning-color, 30)!important;
    color:white;
  }
}

.outlet-footer a.btn-secondary {
  text-decoration:none;
  color:black;
  i.fa {margin-right:5px;}
}