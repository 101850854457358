//app-accessories {
	//@import "../_variables.scss";
	
	.filter-option{
		background: linear-gradient(to bottom,  rgba(255,255,255,0) 0%,rgba(242,242,242,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#f2f2f2',GradientType=0 ); /* IE6-9 */
		user-select: none; 
		text-align:left!important;position:relative;top:-1px;left:-1px;
		display: block;
		padding: 12px 12px 6px 6px ;
		cursor: pointer;
	}

	.filter-option.active{
		background: linear-gradient(to bottom,  rgba(255,255,255,0) 0%,rgba(200,200,200,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#c8c8c8',GradientType=0 ); /* IE6-9 */
	}

	.filter-option:hover{
		background: linear-gradient(to bottom,  rgba(255,255,255,0) 0%,rgba(180,180,180,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#b4b4b4',GradientType=0 ); /* IE6-9 */
	}
	

	.acc-trim-card.btn {
		text-align:left;
		display:inline-block;
		border:1px solid #ccc;
		border-radius:5px;
		margin-right:10px;
		margin-bottom:10px;
		padding:10px;
		box-shadow:rgba(0, 0, 0, 0.439216) 3px 4px 5px 0px;
		overflow: hidden;
		width: 212px;
		white-space: nowrap;
		/*
		fa-icon {
			position: relative;
			left:-5px;
			top: 15px;
			margin-right: 0;
		}
*/
		&:active {
			position:relative;
			left:5px;
			top:5px;
			box-shadow:none;
		}
		
	}
	.acc-trim-card-img {
		width:190px;
		height: 190px;
		border:1px solid #ccc;
		margin-top:5px;
	}
	.shipNA .acc-trim-card-img {
		z-index:100;
	}
	.shipNA.acc-trim-card div.cardHead:after {
		content: url('/assets/images/shipNA.png');
		position: absolute; 
		padding-right:0px;
		padding-top:0px;
		margin-top:65px;
		z-index:200;
		
	}
	.shipNA.acc-trim-card {
		pointer-events: none;
		opacity: 0.5;
	}
		
	$filter-width: 280px;

	.filter-toggle-btn {
		display:none;
	}
	.routecontent {
		display:flex;
		flex-direction: row; /* this makes columns */
	}
	.body-filters {
		background-color:white;
		display:flex;

		flex-direction: column; /* this makes rows */
		left:0;
		top:0;
		bottom:0;
		width:$filter-width;
		min-width:$filter-width;

		padding-left:10px;
		padding-right:10px;
		padding-bottom:10px;
	}

	.body-cards {

		left:$filter-width;
		right:0;
		top:0;
		bottom:0;
		margin-left:10px;
		margin-right:-10px;
		margin-top:-10px;
		margin-bottom:-10px;

		overflow-y:auto;
	}
	.body-full{
		background-color:white;

		left:0;
		right:0;
		top:0;
		bottom:0;
		padding-left:10px;
		padding-right:10px;

		overflow-y:auto;
	}
	@media only screen and (max-width: 768px) {
		.body-filters {

			opacity:0.95;
			background-color:#F8F8F8;
			box-shadow: 4px 5px 20px -2px rgba(0,0,0,0.7);
		}
		.body-cards {
			left:0;
		}
		.filter-toggle-btn {
			display:inline-block;
		}
		.media-left {
			display:inline-block
		}
	}
/*
	.body-filters.belowtabs, .body-cards.belowtabs, .body-full.belowtabs {
		//top:55px;
	}
*/
	fa-stack{
		vertical-align: middle;
		position: relative;
		top: -2px;
	}


//}
/*
.accessories-content{
	//ended up using these in trims too
}
*/
.accessory-heading-row{
	text-decoration:underline;
	background:mix(white, $brand-color, 100)!important;
	&:hover {
		cursor:pointer;
		background:mix(white, $brand-color, 60)!important;
	}
}
.accessory-heading-row.expanded{
	background:mix(white, $brand-color, 25)!important;
	color:white;
}

.accessory-pricing-row{
	background:mix(white, $brand-color, 80)!important;
	border-bottom:5px solid white;
}
.acc-trim-card.btn.active {
	box-shadow:rgba(0, 0, 0, 0.439216) 3px 4px 5px 0px;
	background-color: #e6e6e6;
}