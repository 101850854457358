app-manage-users {

  .scrollable-table{
    td, th{
      font-stretch: 50% !important;
    }
    
  }
  .searchbar {
    flex-shrink: 0;
  }
  .example-viewport{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    overflow:auto;
    height: calc(100vh - 10em);
    min-height: 2em;
  }

  .example-item{
    display: flex;
    flex-direction: column;
    height: 50px;
  }
  .red {
    color:red;
  }

  .asc {
    color:#00665E;
  }
  .blue {
    color:blue;
  }
  .orange {
    color:orange;
  }

  .table-header-rotated {
    border-bottom: 1px solid #dddddd;
    thead tr th {
      white-space: nowrap;
      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
    th.row-header {
      width: auto;
    }
    td {
      width: 40px;
      border-top: 1px solid #dddddd;
      border-left: 1px solid #dddddd;
      border-right: 1px solid #dddddd;
      vertical-align: middle;
      //text-align: center;
    }
    th.rotate-45 {
      height: 80px;
      width: 40px;
      min-width: 40px;
      max-width: 40px;
      position: relative;
      vertical-align: bottom;
      padding: 0;
      font-size: 12px;
      line-height: 0.8;
      > div {
        position: relative;
        top: 0px;
        left: 40px;
        height: 100%;
        -ms-transform: skew(-45deg, 0deg);
        -moz-transform: skew(-45deg, 0deg);
        -webkit-transform: skew(-45deg, 0deg);
        -o-transform: skew(-45deg, 0deg);
        transform: skew(-45deg, 0deg);
        overflow: hidden;
        border-left: 1px solid #dddddd;
        border-right: 1px solid #dddddd;
        border-top: 1px solid #dddddd;
      }
      span {
        -ms-transform: skew(45deg, 0deg) rotate(315deg);
        -moz-transform: skew(45deg, 0deg) rotate(315deg);
        -webkit-transform: skew(45deg, 0deg) rotate(315deg);
        -o-transform: skew(45deg, 0deg) rotate(315deg);
        transform: skew(45deg, 0deg) rotate(315deg);
        position: absolute;
        bottom: 30px;
        left: -25px;
        display: inline-block;
        width: 85px;
        text-align: left;
      }
    }
  }


  .red-fade {
    background-color: rgba(255,0,0,0.1);
  }
  .asc-fade {
    background-color: rgba(0,102,94,0.1);
  }
  .blue-fade {
    background-color: rgba(0,0,255,0.1);
  }
  .orange-fade {
    background-color: rgba(255,165,0,0.1);
  }

  .table-striped>tbody>tr.selected  {
    background-color: rgba(0,102,94,0.90);
    td {
      color: white;
      fa-icon > svg {
        filter: drop-shadow(0px 0px 3px white) !important;
      }
    }
    
  }

}