@charset "UTF-8";
@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';
/* site variables */
@import url("https://fonts.googleapis.com/css2?family=Fira+Sans+Condensed:ital,wght@0,400;0,700;1,400;1,700&family=Fira+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap");
::selection {
  color: white;
  background: #00665E;
}

::-moz-selection {
  color: white;
  background: #00665E;
}

svg text::selection {
  background: none;
}

.btn-default {
  color: #333;
  background-color: #fff;
  border-color: #ccc;
}
.btn-default:active {
  color: black;
}

.btn-primary {
  background: #00665E !important;
  color: #ffffff;
  border-width: 0;
}
.btn-primary:hover {
  background: #4d948e !important;
  color: #ffffff;
  border-width: 0;
}

.btn-secondary {
  background: #99c2bf !important;
  color: #000000;
}
.btn-secondary:hover {
  background: #bfd9d7 !important;
  color: #000000;
}
.btn-secondary:active, .btn-secondary:focus {
  color: black !important;
}

.btn-tertiary {
  background: #0f5b99 !important;
  color: #ffffff;
  border-width: 0;
}
.btn-tertiary:hover {
  background: #578cb8 !important;
  color: #ffffff;
  border-width: 0;
}

.btn-gradient {
  background-image: linear-gradient(rgba(255, 255, 255, 0) 0%, #c8c8c8 100%);
}

.btn-warning-secondary {
  background: #c2999c !important;
  color: #000000;
}
.btn-warning-secondary:hover {
  background: #ceadb0 !important;
  color: #000000;
}

.btn-email span.caret {
  position: absolute;
  right: 6px;
  top: 15px;
  left: auto;
}

button i {
  position: relative;
  left: -5px;
  top: 2px;
  font-size: 1.3em !important;
}

.btn:focus, .btn:active {
  outline: none !important;
}

button:focus {
  outline: 0;
}

.btn.disabled, .btn[disabled], fieldset[disabled] .btn {
  cursor: not-allowed;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none;
  opacity: 0.5;
}

.btn-warning-dark {
  background-color: #6a220e;
  color: white;
}
.btn-warning-dark:focus {
  color: white;
}
.btn-warning-dark:hover {
  background: #976456 !important;
  color: white;
}

.outlet-footer a.btn-secondary {
  text-decoration: none;
  color: black;
}
.outlet-footer a.btn-secondary i.fa {
  margin-right: 5px;
}

/*
// not really needed, because font-stretch gets the job done 
@font-face {
	font-family: 'Arial';
	src: url('/assets/fonts/Arial-Bold.woff2') format('woff2'),
			url('/assets/fonts/Arial-Bold.woff') format('woff');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Arial Narrow';
	src: url('/assets/fonts/ArialNarrow-Bold.woff2') format('woff2'),
			url('/assets/fonts/ArialNarrow-Bold.woff') format('woff');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Arial Narrow';
	src: url('/assets/fonts/ArialNarrow-BoldItalic.woff2') format('woff2'),
			url('/assets/fonts/ArialNarrow-BoldItalic.woff') format('woff');
	font-weight: bold;
	font-style: italic;
}

@font-face {
	font-family: 'Arial';
	src: url('/assets/fonts/Arial-Italic.woff2') format('woff2'),
			url('/assets/fonts/Arial-Italic.woff') format('woff');
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: 'Arial';
	src: url('/assets/fonts/Arial-Black.woff2') format('woff2'),
			url('/assets/fonts/Arial-Black.woff') format('woff');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'Arial Narrow';
	src: url('/assets/fonts/ArialNarrow-Italic.woff2') format('woff2'),
			url('/assets/fonts/ArialNarrow-Italic.woff') format('woff');
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: 'Arial Narrow';
	src: url('/assets/fonts/ArialNarrow.woff2') format('woff2'),
			url('/assets/fonts/ArialNarrow.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Arial';
	src: url('/assets/fonts/Arial-BoldItalic.woff2') format('woff2'),
			url('/assets/fonts/Arial-BoldItalic.woff') format('woff');
	font-weight: bold;
	font-style: italic;
}

@font-face {
	font-family: 'Arial';
	src: url('/assets/fonts/Arial.woff2') format('woff2'),
			url('/assets/fonts/Arial.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}
*/
/* site variables */
::selection {
  color: white;
  background: #00665E;
}

::-moz-selection {
  color: white;
  background: #00665E;
}

svg text::selection {
  background: none;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
@keyframes pulse {
  50% {
    background: #00665E;
  }
}
.imageZoomMask {
  z-index: 20000;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: zoom-out;
}

.imageZoom {
  padding: 50px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.imageZoom img {
  background-color: white;
  box-shadow: 0px 0px 17px 5px rgba(0, 0, 0, 0.75);
  width: auto;
  height: auto;
  max-height: 95vmin;
  max-width: 95vmin;
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
}

.loadingMask {
  z-index: 20000;
  background-color: rgba(255, 255, 255, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  pointer-events: none;
}

.active {
  opacity: 1;
  pointer-events: all;
}

.loading {
  border-radius: 50%;
  width: 75px;
  height: 75px;
  border-top-color: #00665E;
  animation: spin 1s infinite linear;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.loading--double {
  border-style: double;
  border-width: 0.5rem;
}

.loading-pulse {
  position: relative;
  width: 18.75px;
  height: 75px;
  background: rgba(0, 102, 94, 0.2);
  animation: pulse 750ms infinite;
  animation-delay: 250ms;
}
.loading-pulse:before, .loading-pulse:after {
  content: "";
  position: absolute;
  display: block;
  height: 50px;
  width: 18.75px;
  background: rgba(0, 102, 94, 0.2);
  top: 50%;
  transform: translateY(-50%);
  animation: pulse 750ms infinite;
}
.loading-pulse:before {
  left: -37.5px;
}
.loading-pulse:after {
  left: 37.5px;
  animation-delay: 500ms;
}

.circle-loading {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 120px;
  height: 120px;
}

.circular {
  animation: rotate 2s linear infinite;
  height: 100%;
  transform-origin: center center;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.path {
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
  stroke-linecap: round;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}
@keyframes color {
  100%, 0% {
    stroke: #00665E;
  }
}
body .toast-msg {
  animation-duration: 0.25s;
  animation-name: pop-in;
}

@-webkit-keyframes pop-in {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.5);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
  }
}
@-moz-keyframes pop-in {
  0% {
    opacity: 0;
    -moz-transform: scale(0.5);
  }
  100% {
    opacity: 1;
    -moz-transform: scale(1);
  }
}
@keyframes pop-in {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
.toast-msg {
  display: inline-block;
  background-color: #ffffbf;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  bottom: 60px;
  font-size: 2em;
  padding: 5px 10px;
  border: 1px solid black;
  font-weight: bold;
  opacity: 1;
  transition: opacity 0.5s ease;
  margin-bottom: 5px;
}

#toast {
  position: absolute;
  height: 60%;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10000;
  width: 100%;
  text-align: center;
  pointer-events: none;
}

* {
  font-family: "Fira Sans", Verdana, Arial, Helvetica, sans-serif !important;
}

html {
  background-color: #eee;
  margin: 0;
  padding: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: row;
  /* this makes columns */
}

body {
  font-family: "Helvetica Neue", "Arial", sans-serif !important;
  font-size: 14px;
  margin: 0;
  padding: 0;
  background-color: white;
  margin-left: auto;
  margin-right: auto;
  max-width: 1280px;
  box-shadow: 0 0 24px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  /* and this makes rows */
}

/*
// If you are updating an existing project based on an earlier version of Bootstrap, 
// you can use sass extend to apply the rules to the old class names:
.pull-right {
	@extend .float-right;
}
.pull-left {
	@extend .float-left;
}
*/
app-root {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

router-outlet + * {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

header.appheader {
  display: flex;
  flex-direction: column;
  color: black;
  background-color: white;
  padding: 0;
}

div.approute {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

header, footer {
  display: flex;
  flex-direction: column;
  background-color: #00665e;
  color: white;
  padding: 5px;
}

footer {
  box-shadow: 0 -4px 4px -3px rgba(0, 0, 0, 0.75);
}

div.routecontent {
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 1px;
  flex-direction: column;
  overflow: auto;
  height: 100%;
  width: 100%;
  padding: 10px;
  box-shadow: inset 0 8px 10px -10px black;
}

@media (min-width: 30em) {
  .ungrid-row {
    width: 100%;
    display: table;
    table-layout: fixed;
  }

  .ungrid-col {
    display: table-cell;
  }
}
.control-label {
  font-weight: bold;
  text-align: right;
  padding-right: 3px;
}

.form-group {
  margin-bottom: 15px !important;
}

.form-control {
  border: 1px solid #ccc;
  background-color: #f8f8f8 !important;
}

.flipH {
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  filter: FlipH;
  -ms-filter: "FlipH";
}

a, a.textlink {
  color: #00665E;
  text-decoration: underline;
}

button:active,
button:focus,
textarea:focus,
input[type=text]:focus,
input[type=password]:focus,
input[type=datetime]:focus,
input[type=datetime-local]:focus,
input[type=date]:focus,
input[type=month]:focus,
input[type=time]:focus,
input[type=week]:focus,
input[type=number]:focus,
input[type=email]:focus,
input[type=url]:focus,
input[type=search]:focus,
input[type=tel]:focus,
input[type=color]:focus,
select:focus,
input:focus,
.uneditable-input:focus {
  border-color: rgba(0, 102, 94, 0.8) !important;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(0, 102, 94, 0.6) !important;
  outline: 0 none !important;
}

textarea, input, select {
  background-color: #f8f8f8 !important;
}

.account {
  /*
  position: fixed;
  top:0;
  left:0;
  right:0;
  bottom:0;
  */
  background: url("/assets/images/wp.jpg") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-color: white;
}
.account p {
  background-color: transparent !important;
}

.navbar {
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
}

.nav-tabs {
  margin-bottom: -1px !important;
}

.tab-content {
  margin-top: 0 !important;
}

.nav-tabs.htabs {
  flex-direction: row;
  padding-left: 10px;
}

.toolbar-spacer {
  flex: 1 1 auto;
}

a.title {
  color: white;
}

.btnAAD {
  background-color: darkblue;
  color: white;
}

.btnB2C {
  background-color: #00665e;
  color: white;
}

.btnLogout {
  background-color: red;
  color: white;
}

.dropdown-item.active {
  color: black;
  background-color: #99c2bf;
}

.dropdown-item fa-icon,
button fa-icon {
  margin-right: 0.75em;
}

button.iconBtn fa-icon {
  margin-right: 0;
}

.btnRoundIcon {
  width: 2em;
  height: 2em;
  padding: 0;
  border-radius: 1em;
  margin-left: 10px;
}
.btnRoundIcon fa-icon {
  margin-right: 0;
}

.btnRoundIcon.btn-xs {
  width: 1.65em;
  height: 1.65em;
  padding: 0;
  border-radius: 0.75em;
}

.eNav-logo {
  height: 2em;
}

.loader {
  pointer-events: none;
  height: 10px;
  width: 0%;
  border: 2px solid white;
  background-color: #99c2bf;
  animation-duration: 1s;
  animation-name: load-in;
}

@keyframes load-in {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
    display: none;
  }
}
.menu-item-svg {
  height: 1em;
  width: 1em;
  margin-right: 0.5em;
  margin-left: -0.1em;
}

.dropdown-menu {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}

.dropdown-toggle:hover, .dropdown-item:hover {
  background-color: #00665E;
  color: white;
}

.whiteBg {
  background-color: white;
}

.roundIcon {
  border-radius: 0.5em;
}

.dropdown-item-parent {
  display: block;
  width: 100%;
  padding: 0.25rem 1rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item-parent::after {
  display: inline;
  float: right;
  text-align: right;
  margin-top: 0.6em;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}

.dropdown-item-child.dropdown-menu.show {
  margin-left: -10px !important;
  margin-top: -5px !important;
}

a[target=_blank] {
  cursor: alias;
}

.btnSocial {
  font-size: 2.8em;
  margin-right: 0.1em;
  margin-bottom: -5px;
}

.social-buttons .btnSocial {
  text-decoration: none;
  display: inline-flex;
}

.facebookIcon {
  color: #3b5998;
}

.twitterIcon {
  color: #00acee;
}

.linkedInIcon {
  color: #0e76a8;
}

.youtubeIcon {
  color: #c4302b;
}

.pinterestIcon {
  color: #E60023;
}

.houzzIcon {
  color: #4DBC15;
}

.instagramIcon {
  color: #d92e7f;
}

.insta-icon {
  top: 3px;
  position: relative;
  width: 36px;
  height: 36px;
  border-radius: 20%;
  background: radial-gradient(circle at 33% 100%, #fed373 4%, #f15245 30%, #d92e7f 62%, #9b36b7 85%, #515ecf);
}

.insta-icon:after,
.insta-icon:before {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 25px;
  height: 25px;
  border: 2px solid #fff;
  transform: translate(-50%, -50%);
  content: "";
}

.insta-icon:before {
  border-radius: 20%;
}

.insta-icon:after {
  width: 11px;
  height: 11px;
  border-radius: 50%;
}

.dropdown-item:hover fa-icon.facebookIcon {
  color: white;
}

.dropdown-item:hover fa-icon.twitterIcon {
  color: white;
}

.dropdown-item:hover fa-icon.linkedInIcon {
  color: white;
}

.modal-header {
  font-size: 15px !important;
  padding: 14px;
  line-height: 15px;
  background-color: #00665E;
  color: white;
  border-radius: 0.75em 0.75em 0 0;
}
.modal-header h4 {
  font-size: 18px;
  line-height: 18px;
  padding: 0;
  margin: 0;
}

.modal-title fa-icon {
  margin-right: 10px;
}

.modal-footer {
  font-size: 15px !important;
  padding: 14px;
  line-height: 15px;
  background-color: #00665E;
  color: white;
  border-radius: 0 0 0.75em 0.75em;
  text-align: right;
}

.modal-content {
  font-size: 14px !important;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  border: 2px solid #00665E;
  border-radius: 1em;
  margin: -1px;
}

.dist-cols-parent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.dist-cols-child {
  display: flex;
  align-items: center;
}

footer a {
  color: white;
}
footer a:hover {
  color: white;
}

.unpad {
  margin: 0;
  padding: 0;
}

.closeBtn {
  border: 0;
  background-color: transparent;
  color: white;
  font-size: 2em;
  padding: 0;
}
.closeBtn fa-icon {
  margin: 0;
}

modal-container {
  background-color: rgba(0, 0, 0, 0.25) !important;
}

.scroll-Item {
  cursor: pointer;
  border-bottom: 1px solid #ccc;
}
.scroll-Item:hover {
  background-color: rgba(0, 102, 94, 0.25);
}

.selected {
  background-color: rgba(0, 102, 94, 0.5);
}
.selected:hover {
  background-color: rgba(0, 102, 94, 0.5);
}

.self-send.active {
  background-color: #99c2bf;
  color: #000;
}

.self-send {
  cursor: pointer;
  border-radius: 3px;
  padding: 2px;
  display: inline-block;
  margin: 1px;
  padding: 1px;
}

label input[type=checkbox] {
  margin-right: 7px;
  transform: scale(1.2);
}

.hlrows tr:hover {
  background-color: rgba(0, 102, 94, 0.5) !important;
}

.hlrows td {
  font-stretch: condensed;
  cursor: pointer;
}

.dialog-scroll-content {
  max-height: 300px;
  overflow-y: scroll;
  /*max-width:400px;*/
  background-color: white;
  border: 1px solid #cccccc;
}

.scroll-item {
  border: 1px solid white;
  padding: 2px;
  cursor: pointer;
}

.scroll-item:hover {
  border: 1px solid #00665E;
}

.dialog-scroll-content .scroll-item, .vscroll .scroll-item {
  background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, #f2f2f2 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, #f2f2f2 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #f2f2f2 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#00ffffff", endColorstr="#f2f2f2",GradientType=0 );
  /* IE6-9 */
  user-select: none;
}

/*
.dialog-scroll-content .scroll-Item:nth-child(even) {
	 background-color: #eee;
}
	*/
.dialog-scroll-content .scroll-item.selected, .vscroll .scroll-item.selected {
  color: black;
  background-color: #00998d;
  border: 1px solid #00665E;
  /*
   background-color: #00665E;
   color: white;*/
}

.dialog-scroll-inline-header {
  background-color: #cccccc;
  font-weight: bold;
  padding: 3px;
}

.scroll-item table td {
  padding: 7px;
  user-select: none;
}

.dropdown-header {
  font-size: 1em;
  position: relative;
  left: -5px;
  top: -2px;
}

.dropdown-header i {
  position: relative;
  left: -7px;
  top: 0px;
}

.dropdown-header .pull-right {
  position: relative;
  left: 15px;
}

.dropdown-submenu:hover > a {
  color: #fff;
  text-decoration: none;
  background-color: #00665E;
}

.dropdown-submenu:hover > a:after {
  border-left-color: #fff;
}

.menu-detail {
  white-space: nowrap;
  margin-top: 5px;
  margin-left: 15px;
  margin-right: 10px;
  line-height: 1.1em;
  padding: 5px;
}

.btn-xs,
.btn-group-xs > .btn {
  padding: 1px 5px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}

.waggle {
  animation-duration: 0.25s;
  animation-name: jello-horizontal;
  position: relative;
  top: 0;
  right: 0;
  background-color: #99c2bf !important;
  z-index: 100000;
}

@keyframes waggle {
  0% {
    transform: scale(1);
    top: 0;
    right: 0;
    background-color: #99c2bf !important;
  }
  49% {
    transform: scale(1.2);
    top: 0;
    right: 0;
    background-color: #99c2bf !important;
  }
  50% {
    transform: scale(1.2);
    top: 0;
    right: 0;
  }
  75% {
    background-color: white;
  }
  100% {
    transform: scale(1);
    top: 0;
    right: 0;
    background-color: #99c2bf !important;
  }
}
@keyframes jello-horizontal {
  0% {
    transform: scale3d(1.25, 0.75, 1);
    background-color: white;
  }
  30% {
    transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    transform: scale3d(1.05, 0.95, 1);
  }
  100% {
    transform: scale3d(1, 1, 1);
    background-color: #99c2bf !important;
  }
}
.radio-btn fa-stack {
  vertical-align: middle;
}

/*
.radio-btn .fa-stack-2x{
	//opacity:0.5;
}
*/
.color-menu-image {
  position: relative;
  top: -1px;
  left: -10px;
  height: 20px;
  width: 20px;
  border: 1px solid #fff;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.75);
}

.input-sm {
  height: 30px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}

.disabled {
  pointer-events: none !important;
  opacity: 0.3 !important;
}

.modal-body {
  max-height: calc(100vh - 180px);
  overflow-y: auto;
}

.modal-header button {
  opacity: 1;
  font-size: 1.5em;
}
.modal-header fa-icon {
  color: white;
}

.media {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
  margin-top: 10px;
}
.media:last-child {
  border-bottom: 1px solid white;
}
.media:margin-child {
  padding-top: 0;
}
.media .media-body {
  margin-top: 15px;
  margin-left: 15px;
  margin-bottom: 0px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.cardHead {
  display: flex;
  width: 100%;
}

.cardCtrl {
  position: relative;
  left: -5px;
}

.cardDesc {
  display: flex;
  align-items: center;
  white-space: initial;
  text-overflow: ellipsis;
  line-height: 16px;
  font-size: 14px;
  font-weight: bold;
}
.cardDesc.topAligned {
  align-items: flex-start;
  font-size: 18px;
  line-height: 20px;
}

/*
table.cardHead {
	margin-top: -2px;
}
td.cardCtrl {
	//vertical-align: top;
}
td.cardDesc {
//	position: relative;
	//top: -2px;
//	left: -2px;
	//vertical-align: middle;
	white-space: initial;
	text-overflow: ellipsis;
	line-height: 16px;
	font-size: 14px;
	font-weight: bold;
}
*/
.accessory-heading-row {
  text-decoration: underline;
  cursor: pointer;
  border-bottom: 1px solid #ccc;
}
.accessory-heading-row:hover {
  background-color: #99c2bf;
}
.accessory-heading-row:last-child {
  border-bottom: 1px solid white;
}
.accessory-heading-row .color-menu-image {
  position: relative;
  left: -2px;
}

accprice, .trimprice {
  display: flex;
  flex-direction: reverse;
  align-items: center;
  justify-content: right;
}

.btn-close {
  background-image: none !important;
}

.cart-content {
  padding: 10px;
}

.cart-content .ungrid-col {
  vertical-align: top;
}

.cart-content .ungrid-col label.top-pad {
  padding-top: 5px;
}

.cart-content table.cart-item-list {
  width: 100%;
}

.cart-content table.cart-item-list td {
  border: 1px solid #ddd;
}

.cart-content table.cart-item-list thead {
  background-color: #99c2bf !important;
  color: black;
}

.cart-content table.cart-item-list thead th {
  border-bottom: 1px solid #00665E;
}

.cart-content table.cart-item-list thead th.first-col {
  border-left: 1px solid #99c2bf;
  width: 56px;
}

.cart-content table.cart-item-list thead th.last-col {
  border-right: 1px solid #99c2bf;
}

.cart-content table.cart-item-list thead tr {
  margin-bottom: 3px;
}

.cart-content table.cart-item-list tbody tr:nth-child(even) {
  background-color: #eee;
}

.cart-content table.cart-item-list tbody td {
  padding: 3px;
  line-height: 1.2em;
}

.cart-content table.cart-item-list .gtotal {
  font-size: 1.2em;
}

.cart-content table.cart-item-list .gsubtotal {
  font-size: 1.2em !important;
  font-weight: 400 !important;
}

.cart-content table.cart-item-list .rpad {
  padding-right: 10px;
  padding-left: 10px;
}

.cart-content table.cart-item-list .first-col {
  width: 56px;
}

.cart-content table.cart-item-list .last-col {
  padding: 3px;
}

.cart-content .cart-input {
  width: 75px;
}

.cart-content .cart-detail-row {
  display: block;
}

.cart-content .color-menu-image {
  margin-right: 4px;
  left: 0;
}

.smallStack {
  height: 1.4em;
  width: 1.4em;
  left: -0.2em;
}

img.cart-thumb {
  width: 50px;
  height: 50px;
  margin-left: 1px;
  margin-right: 1px;
  border: 1px solid #ddd;
  background-color: white;
  cursor: zoom-in;
}

img.cart-thumb:hover {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(0, 102, 94, 0.6) !important;
}

.ng-tooltip {
  outline: none;
}

.ng-tooltip strong {
  line-height: 30px;
}

.ng-tooltip:hover {
  text-decoration: none;
}

.ng-tooltip span {
  z-index: 10;
  display: none;
  padding: 14px 20px;
  margin-top: -30px;
  margin-left: 28px;
  width: 180px;
  line-height: 16px;
}

.ng-tooltip:hover span {
  display: inline;
  position: absolute;
  color: #111;
  border: 1px solid #DCA;
  background: #fffAF0;
}

.callout {
  z-index: 20;
  position: absolute;
  top: 30px;
  border: 0;
  left: -12px;
}

#cartprint * {
  font-family: arial;
  font-size: 10pt !important;
}

#cartprint table {
  border-collapse: collapse;
}

#cartprint table.darkborder {
  border-collapse: collapse;
}

#cartprint table.darkborder td {
  border: 1px solid black;
  text-align: center;
}

#cartprint table.darkborderl {
  border-collapse: collapse;
}

#cartprint table.darkborderl td {
  border: 1px solid black;
}

#cartprint table.nobottom td, #cartprint tr.nobottom td {
  border-bottom: 0px;
}

#cartprint table.notop td, #cartprint tr.notop td {
  border-top: 0px !important;
}

#cartprint td {
  vertical-align: top;
  padding: 0;
  margin: 0;
}

#cartprint .headerrow {
  border-bottom: 1px solid black !important;
}

#cartprint tr.headerrow td {
  font-weight: bold;
  border-bottom: 0px !important;
}

#cartprint h1 {
  margin: 0;
  padding: 0;
  font-size: 20pt !important;
  font-weight: bold;
}

#cartprint .fixedwidth {
  font-family: courier;
  margin-left: 10px;
  font-weight: bold;
}

#cartprint table.noborder td {
  border: 0px;
}

#cartprint table.fixedwidth td {
  font-family: courier;
  border: 0;
  text-align: right;
  padding-left: 30px;
}

#cartprint td.pad {
  padding: 4px;
}

#cartprint .lineitemdetail {
  padding-bottom: 5px;
}

.attachment {
  display: inline-block;
  background-color: rgba(0, 0, 0, 0.075);
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  margin-bottom: 5px;
  padding: 3px 3px 3px 6px;
  vertical-align: middle;
  margin-right: 10px;
  animation-duration: 0.25s;
  animation-name: fade-in;
}

.attachment i {
  margin-right: 0.25em;
}

.attachment button {
  margin-left: 1em;
}

.attachmentContainer {
  padding-bottom: 0;
}

.attachmentContainer:before,
.attachmentContainer:after {
  content: "";
  display: table;
}

.attachmentContainer:after {
  clear: both;
}

.attachmentContainer {
  zoom: 1;
  /* ie 6/7 */
}

@keyframes pulse {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}
.pulse {
  animation: pulse 1s infinite ease-in-out;
}

@keyframes pulseErrorText {
  0% {
    color: rgba(255, 0, 0, 0.5);
  }
  50% {
    color: red;
  }
  100% {
    color: rgba(255, 0, 0, 0.5);
  }
}
.pulseErrorText {
  color: red;
  animation: pulseErrorText 1s infinite ease-in-out;
}

@keyframes pulseErrorTextBk {
  0% {
    background: rgba(255, 0, 0, 0.5);
  }
  50% {
    background: red;
  }
  100% {
    background: rgba(255, 0, 0, 0.5);
  }
}
.pulseErrorTextBk {
  color: white;
  animation: pulseErrorText 1s infinite ease-in-out;
}

.flexBtn {
  display: flex;
  align-items: center;
}

.angleError {
  color: red;
  animation: pulseErrorText 1s infinite ease-in-out;
}

.angleError::selection {
  color: white;
  background: red;
  animation: pulseErrorTextBk 1s infinite ease-in-out;
}

.filter-toggle-btn {
  display: none;
}

.filterMenuBody {
  display: inline-block;
}

@media only screen and (max-width: 768px) {
  .filter-toggle-btn {
    display: inline-block;
  }

  .filterMenuBody {
    display: none;
  }
}
.lastName {
  display: none;
}

@media (min-width: 768px) {
  .lastName {
    display: inline-block;
  }
}
@media only screen and (max-width: 410px) {
  .social-buttons {
    display: none;
  }
}
.filterMenu {
  display: block;
  width: 290px;
  padding-left: 10px;
  padding-right: 10px;
}

/*
.modal-dialog {
  position: relative;
  display: table;
  overflow: auto;
  width: auto;
  min-width: 300px;
}
.modal-body { // Restrict Modal width to 90%
  overflow-x: auto !important;
  max-width: 90vw !important;
}*/
ul.nav-tabs-bottom {
  border-bottom: 0;
  margin-bottom: 5px;
  padding-left: 10px;
  border-top: 1px solid #dfdfdf;
}
ul.nav-tabs-bottom li {
  margin-top: -1px;
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
  border-bottom-left-radius: 3px !important;
  border-bottom-right-radius: 3px !important;
  border-bottom: 1px;
}
ul.nav-tabs-bottom li a {
  border-bottom-width: 1px !important;
  border-top: 0 !important;
  border-bottom: 1px;
  border-bottom-color: #dddddd !important;
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
  border-bottom-left-radius: 3px !important;
  border-bottom-right-radius: 3px !important;
}

.pageTitle {
  font-weight: bold;
  font-size: 1.5em;
}
.pageTitle fa-icon {
  font-weight: normal;
  margin-right: 10px;
}

.hemIcon {
  width: 20px;
  position: relative;
  left: -5px;
}

.input-inch {
  background-image: url(/assets/images/inches.png);
  background-position: right top;
  background-repeat: no-repeat;
}

.input-degree {
  background-image: url(/assets/images/degrees.png);
  background-position: right top;
  background-repeat: no-repeat;
}

.trim-edit-layout td {
  vertical-align: top;
}

.trimformlabel {
  display: inline-block;
  border: 0;
  width: 89px;
  text-align: right;
}

.labelFlipper {
  border: 1px solid #ddd;
  background-color: #eee;
  border-radius: 4px;
  cursor: pointer;
  padding-left: 6px;
  padding-right: 6px;
  font-weight: normal;
  vertical-align: middle;
}

.labelFlipper:hover {
  background-color: #ccc;
}

.flangeForm {
  background-color: #fbfbfb;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 10px;
  white-space: normal !important;
}

.flangeForm .form-group {
  display: inline-block !important;
  margin: 0px;
  height: 34px;
}

.flangeForm .form-group .input-group-addon {
  text-align: right !important;
  font-weight: bold;
}

.flangeForm .form-group .input-group-addon.lbl {
  text-align: left !important;
}

.flangeForm .form-group .input-group {
  height: 34px;
  width: 180px;
  margin: 0px;
}

.flangeForm .form-group input, .flangeForm .form-group select {
  text-align-last: center;
  text-align: center;
  padding-left: 0px;
  padding-right: 0px;
}

/*
.lineremovebtn {
  display: none; 
}

.linelabelParent .linelabel {
  display: inline-block; }

.linelabelParent .lineremove {
  display: none; }

.linelabelParent:hover .linelabel {
  display: none; }

.linelabelParent:hover .lineremove {
  display: inline-block; }

.linelabelRoot .linelabel {
  margin-left: 12px; }

.linelabelRoot .lineremove {
  margin-left: 5px; }
*/
.lineremovebtn {
  display: none;
  position: relative;
  left: -16px;
}

.linelabelParent:hover .lineremovebtn {
  display: inline-block;
}

input[type=number] {
  text-align: center;
  padding-left: 0;
  padding-right: 0;
}

input[type=number]::-webkit-inner-spin-button {
  position: absolute;
  width: 12.5%;
  height: 100%;
  top: 0;
  right: 0;
  opacity: 0.5;
}

.input-group-addon.lbl.no-padding {
  padding: 0;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-top: 0;
  width: 35px;
}

.scrolldrop {
  max-height: 350px;
  overflow-y: auto;
  overflow-x: hidden;
  min-width: 100% !important;
  background-attachment: local, local, scroll, scroll;
}

.svgIcon {
  width: 16px;
  height: 16px;
}

.nav-link {
  cursor: pointer;
  padding-left: 15px;
  padding-right: 15px;
}

.nav-tabs .nav-link fa-icon {
  margin-right: 10px;
}

.flangeList {
  display: flex;
  flex-flow: row wrap;
  max-width: 400px;
  flex-direction: row;
}
.flangeList .form-group {
  margin-right: 5px;
}

.flangeList.reverseRow .form-group:nth-child(1) {
  order: 3;
}
.flangeList.reverseRow .form-group:nth-child(2) {
  order: 2;
}
.flangeList.reverseRow .form-group:nth-child(3) {
  order: 1;
}

.flangelabel {
  width: 180px;
  display: block;
  font-weight: bold;
}
.flangelabel .float-start {
  font-weight: normal;
}

.lockVertIcon {
  color: #ccc;
  position: relative;
  left: -12px;
  top: -10px;
  margin-right: -15px;
}

.modal.fade {
  transition: none !important;
}

.modal-header.error, .modal-footer.error {
  background-color: #6a220e;
  color: white;
}

#errorReportFrame {
  width: 400px;
  height: 300px;
  display: inline-block;
  float: right;
}

#errorReportFrame textarea {
  width: 100%;
  height: 6em;
  background-color: #f7f7f7;
  border-radius: 5px;
  margin: 10px 0px;
  padding: 5px;
}

#errorReportFrame button {
  font-size: 1.2em;
  background-color: #066;
  color: white;
  font-weight: bold;
  border-radius: 5px;
  border: 0;
  height: 2em;
  padding: 5px 10px;
  cursor: pointer;
}

#errorReportFrame .border {
  border-radius: 10px;
  border: 2px solid #066;
  padding: 10px;
  background-color: #ffc;
}

#errorReportFrame .rightAlign {
  text-align: right;
}

#errorReportFrame p.title {
  font-weight: bold;
  font-size: 1.2em;
  margin-bottom: 5px;
  width: 100%;
  text-align: center;
}

#errorReportFrame #formSubmitted {
  margin-top: 50px;
  margin-bottom: 50px;
}

#errorReportFrame #submitBtn {
  width: 100%;
}

#errorDisplay::before,
#errorDisplay::after,
#errorDisplay *::before,
#errorDisplay *::after {
  all: unset;
}

.errorJson {
  color: red;
  word-wrap: break-word;
  white-space: pre-wrap !important;
  font-family: Menlo, Monaco, Consolas, Courier New, monospace !important;
}

.errorModal .modal-content {
  border: 2px solid #6a220e;
}

.paymentTermsInstructions, .orderMessage {
  margin: 10px;
  padding: 10px;
  border: 1px solid #B68900;
  background-color: #ffc;
  font-size: 1.2em;
  border-radius: 5px;
  overflow: initial;
}

.rrow {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  grid-gap: 1rem;
}

.trimLeftCol {
  width: 400px;
  display: inline-block;
  margin-right: 10px;
  vertical-align: top;
}

.trimMidCol {
  width: 400px;
  display: inline-block;
  vertical-align: top;
}

.trimRightCol {
  display: block;
  margin-left: auto;
  float: right;
  text-align: right;
}

#updatingVersion {
  position: fixed;
  z-index: 10000;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 10000;
  background-color: rgba(0, 0, 0, 0.75);
  text-align: center;
  padding-top: 25%;
  font-size: 25px;
  line-height: 30px;
  font-weight: bold;
  color: white;
}
#updatingVersion img {
  height: 150px;
  animation: spinFast 1.5s infinite ease-in;
}

.vertical-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes spinFast {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(1080deg);
  }
}
.hovered, .hovered .file-input {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(0, 102, 94, 0.6) !important;
}

.text-right {
  text-align: right;
}

.widetip {
  margin: 5px;
  text-align: left;
}
.widetip img {
  margin-top: 10px;
}

.helpTip.show {
  opacity: 1;
}

.helpTip.bs-tooltip-bottom .tooltip-arrow::before {
  border-bottom-color: #00665E !important;
}

.helpTip .tooltip-inner {
  opacity: 1;
  background-color: white;
  color: black;
  font-size: 125%;
  max-width: fit-content;
  padding: 20px;
  border: 2px solid #00665E;
  animation: pulseError;
}

.helpTip.bs-tooltip-end .tooltip-arrow::before {
  border-right-color: #00665E;
}

.helpTip.bs-tooltip-start .tooltip-arrow::before {
  border-left-color: #00665E;
}

.helpTip.bs-tooltip-top .tooltip-arrow::before {
  border-top-color: #00665E;
}

.helpTip.bs-tooltip-bottom .tooltip-arrow::before {
  border-bottom-color: #00665E;
}

.helpTip.bs-tooltip-bottom .tooltip-arrow::before {
  border-bottom-color: #00665E;
}

.helpTip.angleError .tooltip-inner {
  background-color: #ffc;
  color: red;
  animation: pulseErrorText 1s infinite ease-in-out;
  font-size: 0.9em;
  white-space: nowrap;
  max-width: fit-content;
  padding: 5px;
  border: 2px solid red;
}
.helpTip.angleError .tooltip-inner button {
  font-size: 125%;
  line-height: 1em;
  margin-top: 4px;
  padding: 5px;
}

.helpTip.angleError.bs-tooltip-end .tooltip-arrow::before {
  border-right-color: red;
}

.helpTip.angleError.bs-tooltip-start .tooltip-arrow::before {
  border-left-color: red;
}

.helpTip.angleError.bs-tooltip-top .tooltip-arrow::before {
  border-top-color: red;
}

.helpTip.angleError.bs-tooltip-bottom .tooltip-arrow::before {
  border-bottom-color: red;
}

.helpTip.angleError.bs-tooltip-bottom .tooltip-arrow::before {
  border-bottom-color: red;
}

@media screen {
  #cartprint {
    display: none;
  }
}
@media print {
  html {
    background-color: transparent;
    margin: 0;
    padding: 0;
    overflow: auto;
    position: inherit;
    display: block;
  }

  body {
    overflow: hidden;
    background-color: transparent;
    box-shadow: none;
  }

  #cartprint {
    display: block;
  }

  table {
    border-collapse: collapse;
  }

  table.darkborder {
    border-collapse: collapse;
  }

  table.darkborder td {
    border: 1px solid black;
    text-align: center;
  }

  table.darkborderl {
    border-collapse: collapse;
  }

  table.darkborderl td {
    border: 1px solid black;
  }

  table.nobottom td, tr.nobottom td {
    border-bottom: 0px;
  }

  table.notop td, tr.notop td {
    border-top: 0px !important;
  }

  td {
    vertical-align: top;
    padding: 0;
    margin: 0;
  }

  .headerrow {
    border-bottom: 1px solid black !important;
  }

  tr.headerrow td {
    font-weight: bold;
    border-bottom: 0px !important;
  }

  h1 {
    margin: 0;
    padding: 0;
    font-size: 20pt !important;
    font-weight: bold;
  }

  .fixedwidth {
    font-family: courier;
    margin-left: 10px;
    font-weight: bold;
  }

  table.noborder td {
    border: 0px;
  }

  table.fixedwidth td {
    font-family: courier;
    border: 0;
    text-align: right;
    padding-left: 30px;
  }

  td.pad {
    padding: 4px;
  }

  .lineitemdetail {
    padding-bottom: 5px;
  }

  .approute, .appheader {
    display: none !important;
  }
}
@page {
  margin: 0.75cm;
}
#panel-color-dropdown {
  max-height: 35vh;
  overflow-y: auto;
}

.list-group-item-header {
  color: white;
  background-color: transparent;
}

app-home .routecontent {
  width: 100%;
  background-clip: border-box;
  background-color: white;
  background-image: url(/assets/images/wp-light.jpg);
  background-origin: padding-box;
  background-position-x: 50%;
  background-position-y: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
}
app-home .some-page-wrapper {
  margin-top: 10px;
  margin-left: 20px;
  margin-right: 0px;
  margin-bottom: 0px;
}
app-home .row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}
app-home .flexRow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-evenly;
}
app-home .column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
}
app-home .flexColumn {
  display: flex;
  flex-direction: row;
  flex-basis: 50%;
  justify-content: space-around;
}
app-home .column > div {
  padding: 10px;
}
app-home .welcomeText {
  text-shadow: 3px 3px 7px #969696;
}
@media screen and (min-width: 860px) {
  app-home .column {
    flex: 1;
  }
  app-home .double-column {
    flex: 2;
  }
}
app-home .text-right {
  display: flex;
}
app-home .text-right > * {
  margin-left: auto;
}
app-home .announcmentsHeader {
  font-size: 1.2em;
  font-weight: bold;
  background-color: #00665E;
  color: white;
  padding: 6px;
}
app-home .announcmentsBody {
  padding: 10px;
  background-color: white;
  border-top: 0;
  border-left: 2px solid #00665E;
  border-right: 2px solid #00665E;
  border-bottom: 2px solid #00665E;
}
app-home .cat-tile {
  background-color: white;
  padding: 5px;
  margin: 10px;
  border: 1px solid black;
  box-shadow: 3px 4px 5px 0px rgba(0, 0, 0, 0.44);
  text-align: center;
  display: inline-block;
  text-decoration: none;
  color: black;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}
app-home .cat-tile:hover {
  background-color: white;
  border: 1px solid black;
}
app-home .cat-tile:hover, app-home .cat-tile:focus, app-home .cat-tile:active {
  text-decoration: none;
  color: black;
}
app-home .cat-tile:active {
  position: relative;
  top: 3px;
  left: 3px;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.44);
}
app-home .cat-tile-title {
  white-space: nowrap;
  font-weight: bold;
}
app-home .cat-tile-img img {
  border: 1px solid rgba(0, 0, 0, 0.25);
  width: 200px;
  height: 200px;
}
app-home .shipNA {
  opacity: 0.33 !important;
  pointer-events: none !important;
}
app-home .shipNA div.cat-tile-img:before {
  content: url("/assets/images/shipNA.png");
  position: absolute;
  padding-right: 0px;
  padding-top: 40px;
}
app-home .home-col {
  display: inline-block;
}
@media only screen and (max-width: 980px) {
  app-home .cat-tile-title {
    font-size: 0.8em;
    width: 150px;
  }
  app-home .cat-tile-img img {
    width: 150px;
    height: 150px;
  }
  app-home .home-col {
    display: inline;
  }
  app-home .shipNA div.cat-tile-img:before {
    margin-top: 20px;
    content: url("/assets/images/shipNAsm.png");
  }
}
@media only screen and (max-width: 400px) {
  app-home .cat-tile-title {
    font-size: 1em;
    width: 200px;
    height: 30px;
    line-height: 30px;
  }
  app-home .cat-tile-img img {
    display: none;
  }
}

app-manage-users .scrollable-table td, app-manage-users .scrollable-table th {
  font-stretch: 50% !important;
}
app-manage-users .searchbar {
  flex-shrink: 0;
}
app-manage-users .example-viewport {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  overflow: auto;
  height: calc(100vh - 10em);
  min-height: 2em;
}
app-manage-users .example-item {
  display: flex;
  flex-direction: column;
  height: 50px;
}
app-manage-users .red {
  color: red;
}
app-manage-users .asc {
  color: #00665E;
}
app-manage-users .blue {
  color: blue;
}
app-manage-users .orange {
  color: orange;
}
app-manage-users .table-header-rotated {
  border-bottom: 1px solid #dddddd;
}
app-manage-users .table-header-rotated thead tr th {
  white-space: nowrap;
}
app-manage-users .table-header-rotated thead tr th:hover {
  cursor: pointer;
  text-decoration: underline;
}
app-manage-users .table-header-rotated th.row-header {
  width: auto;
}
app-manage-users .table-header-rotated td {
  width: 40px;
  border-top: 1px solid #dddddd;
  border-left: 1px solid #dddddd;
  border-right: 1px solid #dddddd;
  vertical-align: middle;
}
app-manage-users .table-header-rotated th.rotate-45 {
  height: 80px;
  width: 40px;
  min-width: 40px;
  max-width: 40px;
  position: relative;
  vertical-align: bottom;
  padding: 0;
  font-size: 12px;
  line-height: 0.8;
}
app-manage-users .table-header-rotated th.rotate-45 > div {
  position: relative;
  top: 0px;
  left: 40px;
  height: 100%;
  -ms-transform: skew(-45deg, 0deg);
  -moz-transform: skew(-45deg, 0deg);
  -webkit-transform: skew(-45deg, 0deg);
  -o-transform: skew(-45deg, 0deg);
  transform: skew(-45deg, 0deg);
  overflow: hidden;
  border-left: 1px solid #dddddd;
  border-right: 1px solid #dddddd;
  border-top: 1px solid #dddddd;
}
app-manage-users .table-header-rotated th.rotate-45 span {
  -ms-transform: skew(45deg, 0deg) rotate(315deg);
  -moz-transform: skew(45deg, 0deg) rotate(315deg);
  -webkit-transform: skew(45deg, 0deg) rotate(315deg);
  -o-transform: skew(45deg, 0deg) rotate(315deg);
  transform: skew(45deg, 0deg) rotate(315deg);
  position: absolute;
  bottom: 30px;
  left: -25px;
  display: inline-block;
  width: 85px;
  text-align: left;
}
app-manage-users .red-fade {
  background-color: rgba(255, 0, 0, 0.1);
}
app-manage-users .asc-fade {
  background-color: rgba(0, 102, 94, 0.1);
}
app-manage-users .blue-fade {
  background-color: rgba(0, 0, 255, 0.1);
}
app-manage-users .orange-fade {
  background-color: rgba(255, 165, 0, 0.1);
}
app-manage-users .table-striped > tbody > tr.selected {
  background-color: rgba(0, 102, 94, 0.9);
}
app-manage-users .table-striped > tbody > tr.selected td {
  color: white;
}
app-manage-users .table-striped > tbody > tr.selected td fa-icon > svg {
  filter: drop-shadow(0px 0px 3px white) !important;
}

app-panels {
  /*
    @media (min-width: 600px) {
      .panel-list {
        max-width:49%;
        display:inline-block;
        vertical-align: top;
      }
    }
  */
}
app-panels .panel-tile {
  border: 1px solid black;
  box-shadow: 3px 4px 5px 0px rgba(0, 0, 0, 0.44);
  margin: 10px;
  font-size: 1em !important;
  vertical-align: top;
  padding: 10px;
  margin-bottom: 15px;
}
app-panels li.active a {
  border-bottom: 0;
  position: relative;
  top: 1px;
}
app-panels .tab-pane, app-panels li.active a {
  background-color: white;
}
app-panels .panel-imagef {
  border: 1px solid #ddd;
  width: 150px;
  height: 150px;
  margin-right: 10px;
  float: left;
}
app-panels .panel-title {
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 10px;
  margin-right: 10px;
  display: fles;
}
app-panels .expand-image {
  width: 40%;
  height: 40%;
  max-height: 150px;
  max-width: 150px;
  border: 1px solid #ccc;
  margin-right: 15px;
  margin-bottom: 5px;
  float: left;
  clear: both;
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.75);
}
app-panels .panel-list .col-md-6 {
  padding-left: 0;
  padding-right: 0;
  margin-left: 0;
  margin-right: 0;
}
app-panels .tab-pane {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}
app-panels .tabpanel {
  float: left;
  width: 100%;
}
app-panels .panel-description ul {
  list-style-type: none;
  list-style-position: inside;
  padding-left: 0px !important;
}
app-panels .panel-description li:before {
  content: "●";
  padding-left: 1px;
  padding-right: 5px;
}
app-panels .panel-id {
  white-space: nowrap;
  padding-bottom: 10px;
  position: relative;
  top: -3px;
}
app-panels .alaska-only {
  opacity: 0.25;
}
app-panels .panel-table {
  display: table;
  width: 100%;
  border-collapse: separate;
  border-spacing: 15px;
}
app-panels .panel-tr {
  display: table-row;
}
app-panels .panel-td {
  background-color: #efefef;
  display: table-cell;
  width: 24.9%;
  padding: 10px;
  border: 1px solid black;
  box-shadow: 3px 4px 5px 0px rgba(0, 0, 0, 0.44);
}
app-panels .nav:after, app-panels .nav:before {
  display: inline-block !important;
}
app-panels .nav {
  display: inline-block !important;
  width: 100% !important;
}
@media (max-width: 600px) {
  app-panels .panel-table {
    display: block;
    width: inherit;
  }
  app-panels .panel-tr {
    display: block;
    width: inherit;
  }
  app-panels .panel-td {
    display: block;
    width: inherit;
  }
}

app-login {
  width: 100%;
  height: 100%;
  background-clip: border-box;
  background-color: white;
  background-image: url(/assets/images/wp-light.jpg);
  background-origin: padding-box;
  background-position-x: 50%;
  background-position-y: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
app-login .flex-container {
  height: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
app-login .row {
  width: auto;
  max-width: 500px;
  box-shadow: rgba(0, 0, 0, 0.439216) 3px 4px 5px 0px;
  border: 1px solid black;
  background-color: white;
  flex-direction: column;
  padding: 15px;
}
app-login .flex-item {
  padding: 5px;
  text-align: center;
}
app-login .loginBtn {
  width: 350px;
  border: 1px solid #00665E;
  outline: 1px solid white;
  border-radius: 5px;
  font-size: 1.2em;
  line-height: 1em;
  height: 2em;
}
app-login .logonBox {
  display: inline-block;
  display: block;
  margin: 0 auto !important;
  max-width: 500px;
  background-color: #eee;
  border: 1px solid black;
  padding: 25px;
  margin: 20px;
  box-shadow: rgba(0, 0, 0, 0.439216) 3px 4px 5px 0px;
}
app-login .headerImg {
  display: block;
  width: 80%;
  margin: 0 auto !important;
}
app-login .authIcon {
  height: 2em;
  width: 2em;
  margin-top: 0.25em;
}

.nav-tabs {
  display: flex;
  flex-direction: column;
  /* and this makes rows */
  margin-bottom: -10px;
}

.nav-item {
  display: inline-block;
  margin-right: 5px;
  flex-wrap: wrap;
}
.nav-item a.nav-link.active {
  display: flex;
  background-color: white;
  color: #00665E;
  text-decoration: underline;
  border-bottom: 1px solid white;
}
.nav-item a.nav-link {
  display: flex;
  background-color: #dfdfdf;
  color: #00665E;
  text-decoration: underline;
  border-bottom: 1px solid #dfdfdf;
}

.configure-content, .cutlist-component {
  padding: 10px;
  /*CSS3 extras*/
}
.configure-content .panel-image, .cutlist-component .panel-image {
  border: 1px solid #ccc;
  margin-right: 10px;
  margin-bottom: 10px;
  width: 130px;
  height: 130px;
}
.configure-content .panel-title, .cutlist-component .panel-title {
  font-size: 1.5em;
  font-weight: bold;
}
.configure-content .panel-id, .cutlist-component .panel-id {
  margin-bottom: 10px;
}
.configure-content .color-menu-image, .cutlist-component .color-menu-image {
  position: relative;
  top: -1px;
  left: -10px;
}
.configure-content button .color-menu-image, .cutlist-component button .color-menu-image {
  margin-left: 8px;
}
.configure-content .chipWarning, .cutlist-component .chipWarning {
  color: #aaa;
  font-size: 0.75em;
  padding: 0px;
  font-style: italic;
  margin-bottom: 10px;
}
.configure-content .form-group, .cutlist-component .form-group {
  white-space: nowrap;
}
.configure-content .ng-tooltip span, .cutlist-component .ng-tooltip span {
  border-radius: 4px;
  box-shadow: 5px 5px 8px #CCC;
  z-index: 100;
}
.configure-content .input-group .form-control, .cutlist-component .input-group .form-control {
  z-index: 1 !important;
}
.configure-content table.cutlist, .cutlist-component table.cutlist {
  background-color: white;
}
.configure-content table.cutlist td, .configure-content table.cutlist th, .cutlist-component table.cutlist td, .cutlist-component table.cutlist th {
  white-space: nowrap;
}
.configure-content table.cutlist input.form-control, .cutlist-component table.cutlist input.form-control {
  width: 50px !important;
  display: inline;
  text-align: center;
  padding-left: 0;
  padding-right: 0;
  font-family: Courier New, Courier, monospace;
  font-size: 1.2em;
  font-weight: bold;
}
.configure-content table.cutlist .fractions, .cutlist-component table.cutlist .fractions {
  font-family: Courier New, Courier, monospace;
  font-size: 1.2em;
  font-weight: bold;
}
.configure-content table.cutlist .input-feet, .cutlist-component table.cutlist .input-feet {
  background-image: url(/assets/images/feet.png);
  background-position: right top;
  background-repeat: no-repeat;
  border-right: 0;
}
.configure-content table.cutlist .input-inch, .cutlist-component table.cutlist .input-inch {
  background-image: url(/assets/images/inches.png);
  background-position: right top;
  background-repeat: no-repeat;
}
.configure-content table.cutlist input.inches, .cutlist-component table.cutlist input.inches {
  width: 40px !important;
}
.configure-content table.cutlist input.totinches, .cutlist-component table.cutlist input.totinches {
  width: 80px !important;
}
.configure-content table.cutlist input.qty, .cutlist-component table.cutlist input.qty {
  background-color: #ffe !important;
}
.configure-content table.cutlist select.form-control, .cutlist-component table.cutlist select.form-control {
  padding-left: 0;
  padding-right: 0;
}
.configure-content table.cutlist select.form-control option, .cutlist-component table.cutlist select.form-control option {
  background-color: lightgoldenrodyellow;
  font-weight: bold;
}
.configure-content table.cutlist td.hinttd, .cutlist-component table.cutlist td.hinttd {
  border-left: 0px solid white;
  border-bottom: 0px solid white;
  background-color: white;
}
.configure-content table.cutlist tr.hintrow, .cutlist-component table.cutlist tr.hintrow {
  border-left: 0 !important;
  border-right: 0 !important;
}
.configure-content table.cutlist .btn-cell, .cutlist-component table.cutlist .btn-cell {
  border: 1px solid #aaa;
}
.configure-content table.hints, .cutlist-component table.hints {
  opacity: 0.5;
  font-size: 0.8em;
  width: 100%;
}
.configure-content table.hints tr, .cutlist-component table.hints tr {
  border: 0;
}
.configure-content table.hints td, .cutlist-component table.hints td {
  border: 1px solid white;
  text-align: right;
  padding-left: 3px;
}
.configure-content table.hints td.cmd, .cutlist-component table.hints td.cmd {
  text-align: left;
  font-weight: bold;
}
.configure-content table.hints:hover, .cutlist-component table.hints:hover {
  opacity: 1;
}
.configure-content table.hints2, .cutlist-component table.hints2 {
  opacity: 0.5;
  font-size: 0.8em;
  width: 100%;
}
.configure-content table.hints2 tr, .cutlist-component table.hints2 tr {
  border: 0;
}
.configure-content table.hints2 td, .cutlist-component table.hints2 td {
  border: 1px solid white;
  text-align: center;
}
.configure-content table.hints2 td.cmd, .cutlist-component table.hints2 td.cmd {
  font-weight: bold;
}
.configure-content table.hints2 tr.legend td span, .cutlist-component table.hints2 tr.legend td span {
  border-bottom: 1px solid #ccc;
  font-weight: bold;
  padding: 0 3px;
}
.configure-content table.hints2:hover, .cutlist-component table.hints2:hover {
  opacity: 1;
}
.configure-content table.cutlist > thead > tr, .configure-content table.cutlist > tbody > tr, .cutlist-component table.cutlist > thead > tr, .cutlist-component table.cutlist > tbody > tr {
  border-top: 1px solid #aaa;
  border-left: 1px solid #aaa;
  border-right: 1px solid #aaa;
  border-bottom: 0;
}
.configure-content table.cutlist > thead > tr > th, .cutlist-component table.cutlist > thead > tr > th {
  text-align: center;
  padding: 0;
  background-color: #ccc;
  font-stretch: condensed;
}
.configure-content table.cutlist > tbody > tr > td, .cutlist-component table.cutlist > tbody > tr > td {
  text-align: center;
  padding: 3px;
  white-space: nowrap;
}
.configure-content table.cutlist > tbody > tr.cuts, .cutlist-component table.cutlist > tbody > tr.cuts {
  border-bottom: 0 !important;
}
.configure-content table.cutlist > tbody > tr.cuts > td, .cutlist-component table.cutlist > tbody > tr.cuts > td {
  border-bottom: 0 !important;
}
.configure-content table.cutlist > tbody > tr.error-row, .cutlist-component table.cutlist > tbody > tr.error-row {
  border-top: 0;
}
.configure-content table.cutlist > tbody > tr.error-row > td, .cutlist-component table.cutlist > tbody > tr.error-row > td {
  border-top: 0;
  color: red;
}
.configure-content table.cutlist > tbody > tr > td.first-col, .cutlist-component table.cutlist > tbody > tr > td.first-col {
  padding: 0;
}
.configure-content table.cutlist > tbody > tr.even, .cutlist-component table.cutlist > tbody > tr.even {
  background-color: #eeeeee;
}
.configure-content table.cutlist > tbody > tr.even .first-col, .cutlist-component table.cutlist > tbody > tr.even .first-col {
  background-color: #cccccc;
}
.configure-content table.cutlist > tbody > tr.odd, .cutlist-component table.cutlist > tbody > tr.odd {
  background-color: white;
}
.configure-content table.cutlist > tbody > tr.odd .first-col, .cutlist-component table.cutlist > tbody > tr.odd .first-col {
  background-color: #eeeeee;
}
.configure-content table.line-ctrl, .cutlist-component table.line-ctrl {
  margin: 0;
  padding: 0;
}
.configure-content table.line-ctrl > tbody > tr > td, .cutlist-component table.line-ctrl > tbody > tr > td {
  margin: 0;
  padding: 0;
  text-align: center;
  width: 10px;
  height: 4px !important;
}
.configure-content .updown, .cutlist-component .updown {
  line-height: 1px;
}
.configure-content .updown img, .cutlist-component .updown img {
  opacity: 0.5;
  cursor: pointer;
}
.configure-content .updown img:hover, .cutlist-component .updown img:hover {
  opacity: 1;
  background-color: #aaa;
}
.configure-content img.leftright, .cutlist-component img.leftright {
  opacity: 0.5;
  cursor: pointer;
}
.configure-content img.leftright:hover, .cutlist-component img.leftright:hover {
  opacity: 1;
  background-color: #aaa;
}
.configure-content .hide-updown, .configure-content .hide-leftright, .cutlist-component .hide-updown, .cutlist-component .hide-leftright {
  visibility: hidden;
  pointer-events: none;
}
.configure-content img.hide-leftright, .cutlist-component img.hide-leftright {
  visibility: hidden;
  pointer-events: none;
}
.configure-content .text-left, .cutlist-component .text-left {
  color: black;
  text-align: left;
}
.configure-content .radio-btn, .cutlist-component .radio-btn {
  padding-left: 3px;
  line-height: 1em;
  vertical-align: middle;
  white-space: nowrap;
}
.configure-content .radio-btn input, .cutlist-component .radio-btn input {
  display: none;
}
.configure-content .pack-td, .cutlist-component .pack-td {
  font-family: Arial;
  font-stretch: condensed;
  vertical-align: top;
  padding-right: 10px;
}
.configure-content .pack-td .btn-group, .cutlist-component .pack-td .btn-group {
  font-stretch: condensed;
}
.configure-content th.cutlist-header, .cutlist-component th.cutlist-header {
  font-family: Arial;
  font-stretch: condensed;
}
.configure-content td.cutlist-cell, .cutlist-component td.cutlist-cell {
  white-space: nowrap;
  font-family: Arial;
  font-stretch: condensed;
}

.cutlist-total-table {
  float: right;
  margin: 3px 0;
}
.cutlist-total-table td {
  text-align: right;
  padding: 0 5px;
  font-size: 1.2em;
  font-family: Arial;
  font-stretch: condensed;
}
.cutlist-total-table tr.sub-total {
  border-top: 1px solid #ccc;
}
.cutlist-total-table b {
  font-stretch: condensed;
}

.filter-option {
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #f2f2f2 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#00ffffff", endColorstr="#f2f2f2",GradientType=0 );
  /* IE6-9 */
  user-select: none;
  text-align: left !important;
  position: relative;
  top: -1px;
  left: -1px;
  display: block;
  padding: 12px 12px 6px 6px;
  cursor: pointer;
}

.filter-option.active {
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #c8c8c8 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#00ffffff", endColorstr="#c8c8c8",GradientType=0 );
  /* IE6-9 */
}

.filter-option:hover {
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #b4b4b4 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#00ffffff", endColorstr="#b4b4b4",GradientType=0 );
  /* IE6-9 */
}

.acc-trim-card.btn {
  text-align: left;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.439216) 3px 4px 5px 0px;
  overflow: hidden;
  width: 212px;
  white-space: nowrap;
  /*
  		fa-icon {
  			position: relative;
  			left:-5px;
  			top: 15px;
  			margin-right: 0;
  		}
  */
}
.acc-trim-card.btn:active {
  position: relative;
  left: 5px;
  top: 5px;
  box-shadow: none;
}

.acc-trim-card-img {
  width: 190px;
  height: 190px;
  border: 1px solid #ccc;
  margin-top: 5px;
}

.shipNA .acc-trim-card-img {
  z-index: 100;
}

.shipNA.acc-trim-card div.cardHead:after {
  content: url("/assets/images/shipNA.png");
  position: absolute;
  padding-right: 0px;
  padding-top: 0px;
  margin-top: 65px;
  z-index: 200;
}

.shipNA.acc-trim-card {
  pointer-events: none;
  opacity: 0.5;
}

.filter-toggle-btn {
  display: none;
}

.routecontent {
  display: flex;
  flex-direction: row;
  /* this makes columns */
}

.body-filters {
  background-color: white;
  display: flex;
  flex-direction: column;
  /* this makes rows */
  left: 0;
  top: 0;
  bottom: 0;
  width: 280px;
  min-width: 280px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
}

.body-cards {
  left: 280px;
  right: 0;
  top: 0;
  bottom: 0;
  margin-left: 10px;
  margin-right: -10px;
  margin-top: -10px;
  margin-bottom: -10px;
  overflow-y: auto;
}

.body-full {
  background-color: white;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  padding-left: 10px;
  padding-right: 10px;
  overflow-y: auto;
}

@media only screen and (max-width: 768px) {
  .body-filters {
    opacity: 0.95;
    background-color: #F8F8F8;
    box-shadow: 4px 5px 20px -2px rgba(0, 0, 0, 0.7);
  }

  .body-cards {
    left: 0;
  }

  .filter-toggle-btn {
    display: inline-block;
  }

  .media-left {
    display: inline-block;
  }
}
/*
	.body-filters.belowtabs, .body-cards.belowtabs, .body-full.belowtabs {
		//top:55px;
	}
*/
fa-stack {
  vertical-align: middle;
  position: relative;
  top: -2px;
}

/*
.accessories-content{
	//ended up using these in trims too
}
*/
.accessory-heading-row {
  text-decoration: underline;
  background: white !important;
}
.accessory-heading-row:hover {
  cursor: pointer;
  background: #99c2bf !important;
}

.accessory-heading-row.expanded {
  background: #408c86 !important;
  color: white;
}

.accessory-pricing-row {
  background: #cce0df !important;
  border-bottom: 5px solid white;
}

.acc-trim-card.btn.active {
  box-shadow: rgba(0, 0, 0, 0.439216) 3px 4px 5px 0px;
  background-color: #e6e6e6;
}

app-cart .dropZone[drop-active=true] {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(0, 102, 94, 0.6) !important;
}
app-cart .cart-content {
  padding: 10px;
}
app-cart .cart-content .ungrid-col {
  vertical-align: top;
}
app-cart .cart-content .ungrid-col label.top-pad {
  padding-top: 5px;
}
app-cart .cart-content table.cart-item-list {
  width: 100%;
  /*
  .first-row {
  		//border-top:1px solid red;
  }
  */
}
app-cart .cart-content table.cart-item-list td {
  border: 1px solid #ddd;
}
app-cart .cart-content table.cart-item-list thead {
  background-color: #99c2bf !important;
  color: black;
}
app-cart .cart-content table.cart-item-list thead th {
  border-bottom: 1px solid #00665E;
}
app-cart .cart-content table.cart-item-list thead th.first-col {
  border-left: 1px solid #99c2bf;
  width: 56px;
}
app-cart .cart-content table.cart-item-list thead th.last-col {
  border-right: 1px solid #99c2bf;
}
app-cart .cart-content table.cart-item-list thead tr {
  margin-bottom: 3px;
}
app-cart .cart-content table.cart-item-list tbody {
  /*
  tr {
  	//border-bottom:1px solid #ddd;
  }
  */
}
app-cart .cart-content table.cart-item-list tbody tr:nth-child(even) {
  background-color: #eee;
}
app-cart .cart-content table.cart-item-list tbody td {
  padding: 3px;
  line-height: 1.2em;
}
app-cart .cart-content table.cart-item-list .gtotal {
  font-size: 1.2em;
}
app-cart .cart-content table.cart-item-list .gsubtotal {
  font-size: 1.2em !important;
  font-weight: 400 !important;
}
app-cart .cart-content table.cart-item-list .rpad {
  padding-right: 10px;
  padding-left: 10px;
}
app-cart .cart-content table.cart-item-list .first-col {
  width: 56px;
}
app-cart .cart-content table.cart-item-list .last-col {
  padding: 3px;
}
app-cart .cart-content .cart-input {
  width: 75px;
}
app-cart .cart-content .cart-detail-row {
  display: block;
}
app-cart .cart-content .color-menu-image {
  margin-right: 4px;
}
app-cart img.cart-thumb {
  width: 50px;
  height: 50px;
  margin-left: 1px;
  margin-right: 1px;
  border: 1px solid #ddd;
  background-color: white;
  cursor: zoom-in;
}
app-cart img.cart-thumb:hover {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(0, 102, 94, 0.6) !important;
}
app-cart #cartprint * {
  font-family: arial;
  font-size: 10pt !important;
}
app-cart #cartprint table {
  border-collapse: collapse;
}
app-cart #cartprint table.darkborder {
  border-collapse: collapse;
}
app-cart #cartprint table.darkborder td {
  border: 1px solid black;
  text-align: center;
}
app-cart #cartprint table.darkborderl {
  border-collapse: collapse;
}
app-cart #cartprint table.darkborderl td {
  border: 1px solid black;
}
app-cart #cartprint table.nobottom td, app-cart #cartprint tr.nobottom td {
  border-bottom: 0px;
}
app-cart #cartprint table.notop td, app-cart #cartprint tr.notop td {
  border-top: 0px !important;
}
app-cart #cartprint td {
  vertical-align: top;
  padding: 0;
  margin: 0;
}
app-cart #cartprint .headerrow {
  border-bottom: 1px solid black !important;
}
app-cart #cartprint tr.headerrow td {
  font-weight: bold;
  border-bottom: 0px !important;
}
app-cart #cartprint h1 {
  margin: 0;
  padding: 0;
  font-size: 20pt !important;
  font-weight: bold;
}
app-cart #cartprint .fixedwidth {
  font-family: courier;
  margin-left: 10px;
  font-weight: bold;
}
app-cart #cartprint table.noborder td {
  border: 0px;
}
app-cart #cartprint table.fixedwidth td {
  font-family: courier;
  border: 0;
  text-align: right;
  padding-left: 30px;
}
app-cart #cartprint td.pad {
  padding: 4px;
}
app-cart #cartprint .lineitemdetail {
  padding-bottom: 5px;
}
app-cart .attachment {
  display: inline-block;
  background-color: rgba(0, 0, 0, 0.075);
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  margin-bottom: 5px;
  padding: 3px 3px 3px 6px;
  vertical-align: middle;
  margin-right: 10px;
  animation-duration: 0.25s;
  animation-name: fade-in;
}
app-cart .attachment i {
  margin-right: 0.25em;
}
app-cart .attachment button {
  margin-left: 1em;
}
app-cart .attachmentContainer {
  padding-bottom: 0;
}
app-cart .attachmentContainer:before,
app-cart .attachmentContainer:after {
  content: "";
  display: table;
}
app-cart .attachmentContainer:after {
  clear: both;
}
app-cart .attachmentContainer {
  zoom: 1;
  /* ie 6/7 */
}

app-trims .routecontent {
  display: flex;
  flex-direction: column;
  /* this makes row */
}
app-trims .routecontent .acc-trim-card {
  width: 150px;
}

.trimcat {
  width: -webkit-fill-available;
}

hr {
  margin-top: 0;
}

.td-top {
  vertical-align: top;
}

.trimResult {
  display: flex;
  flex-direction: row;
}

.trim-desc {
  vertical-align: top;
  text-align: right;
  font-weight: bold !important;
  padding-right: 5px;
}

.trim-product-image {
  height: 100px;
  width: 100px;
  border: 1px solid gray;
  margin-right: 10px;
  cursor: zoom-in;
}
.trim-product-image:hover {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(0, 102, 94, 0.6) !important;
}

.trim-result-table {
  display: inline-block;
  width: 100%;
}
.trim-result-table .t-desc {
  width: 30%;
}
.trim-result-table .t-gauge {
  width: 5%;
}
.trim-result-table .t-ctwt {
  width: 5%;
}
.trim-result-table .t-grade {
  width: 5%;
}
.trim-result-table .t-color {
  width: 20%;
}
.trim-result-table .t-compat {
  width: auto;
}

app-orders {
  /*
  	.order-header-cols >tbody>tr>td>table>tr {
  		border-top: 1px solid #efefef;
  	}
  */
  /*
  ul.nav-tabs li i.fa {
  	margin-right: 5px;
  }

  .tab-content {
  	border-top: 0px solid red;
  	border-left: 1px solid #ddd;
  	border-right: 1px solid #ddd;
  	border-bottom: 1px solid #ddd;
  	border-bottom-left-radius: 4px;
  	border-bottom-right-radius: 4px;
  	position: absolute;
  	top: 50px;
  	left: 10px;
  	right: 10px;
  	bottom: 12px;
  }

  #pdf-iframe {
  	position: absolute;
  	top: 1px;
  	left: 0px;
  	right: 0px;
  	bottom: 0px;
  }

  #order-details {
  	padding: 10px;
  	overflow-y: auto;
  	position: absolute;
  	top: 1px;
  	left: 0px;
  	right: 0px;
  	bottom: 0px;
  }





  overflow:hidden;

  .orders-list {
  	position:absolute;
  	top: 0px;
  	bottom:0px;
  	width: 200px;
  	background-color:white;
  	overflow-y:auto;
  	.active {
  		color: $brand-color
  	}
  }
  .order-detail {
  	position:absolute;
  	top: 0px;
  	bottom:0px;
  	left:200px;
  	width: 1080px;
  	background-color:white;
  	overflow:hidden;

  }
  pre {margin:0}

  #order-pdf,#order-details{
  	position:absolute;
  	top:52px;
  	bottom:0px;
  	left:0px;
  	right:0px;

  }
  #order-details {
  	//border:1px solid red;
  	overflow:auto;

  }


  /*
  .tab-pane {
  	height:100%;
  }
  .tab-content {
  	width:100%;
  	overflow-y:auto;
  	position:absolute;
  	left:10px;
  	right:40px;
  	top:45px;
  	bottom:10px;

  	iframe.order-pdf {
  		border:1px solid yellow;

  		width:100%;
  		height:100%;
  	}
  }
  */
  /*iframe.order-pdf {
  		border:0;

  		position:absolute;
  		top: 40px;
  		bottom:40px;
  		left:40px;
  		right:40px;
  	}






  */
}
app-orders .body-cards {
  padding-right: 10px;
  width: 100%;
  display: block;
}
app-orders .tab-content {
  border: 1px solid #ddd;
  border-top: 0;
  height: calc(100% - 75px);
}
app-orders .orders-content {
  padding: 20px;
}
app-orders .tab-pane {
  height: 100%;
}
app-orders iframe {
  width: 100%;
  height: 100%;
  position: relative;
  top: -11px;
}
app-orders .so-detail-label {
  white-space: nowrap;
  font-weight: bold;
  text-align: right;
  padding-right: 5px;
  vertical-align: top;
}
app-orders .order-number-po {
  border-radius: 5px;
  background-color: #99c2bf !important;
}
app-orders #order-items {
  margin-top: 10px;
  margin-bottom: 10px;
}
app-orders #order-items table thead tr {
  background-color: #99c2bf !important;
}
app-orders .noPdf {
  text-align: center;
  margin-top: 10px;
}
app-orders .order-cutlist-item {
  display: block;
  font-family: Courier New, Courier, monospace;
  padding-left: 10px;
}
app-orders .order-cutlist-item span {
  width: 30px;
  display: inline-block;
  text-align: right;
}
app-orders .invalid-reason {
  font-style: italic;
  opacity: 0.66;
  font-size: 0.8em;
}
app-orders #order-status-table {
  width: 100%;
}
app-orders #order-status-table td {
  vertical-align: top;
}
app-orders .order-headers table {
  margin-top: 5px;
  margin-bottom: 5px;
}
app-orders .order-headers td {
  vertical-align: top;
}
app-orders .order-header-cols > tbody > tr > td {
  vertical-align: top !important;
  padding: 3px 5px;
}
@media only screen and (max-width: 1200px) {
  app-orders .order-header-cols {
    display: block;
  }
  app-orders .order-header-cols > tbody > tr {
    display: block;
  }
  app-orders .order-header-cols > tbody > tr > td {
    display: block;
    margin-bottom: 10px;
  }
}
app-orders .order-list-item {
  border-bottom: 1px solid #ccc;
  cursor: pointer;
  padding-left: 5px;
  position: relative;
  left: -7px;
}
app-orders .order-list-item:hover {
  background-color: #99c2bf;
  color: black;
}
app-orders .order-list-item.active {
  background-color: #00665E;
  color: white;
}
app-orders .order-list-item.active:after {
  left: 100%;
  top: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(0, 102, 94, 0);
  border-left-color: #00665E;
  border-width: 7px;
  margin-top: -7px;
}