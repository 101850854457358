app-panels {
  /*
  @media (min-width: 600px) {
    .panel-list {
      max-width:49%;
      display:inline-block;
      vertical-align: top;
    }
  }
*/
  .panel-tile {
    border: 1px solid black;
    box-shadow: 3px 4px 5px 0px rgba(0, 0, 0, 0.44);
    margin: 10px;
    font-size: 1em !important;
    vertical-align: top;
    padding: 10px;
    margin-bottom: 15px;
  }

  li.active a {
    border-bottom: 0;
    position: relative;
    top: 1px;
  }

  .tab-pane, li.active a {
    background-color: white;
  }


  .panel-imagef {
    border: 1px solid #ddd;
    width: 150px;
    height: 150px;
    margin-right: 10px;
    float: left;
  }

  .panel-title {
    font-size: 1.5em;
    font-weight: bold;
    margin-bottom: 10px;
    margin-right: 10px;
		display: fles;
  }

  .expand-image {
    width: 40%;
    height: 40%;
    max-height: 150px;
    max-width: 150px;
    border: 1px solid #ccc;
    margin-right: 15px;
    margin-bottom: 5px;
    float: left;
    clear: both;
		box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.75);
  }

  .panel-list .col-md-6 {
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0;
  }

  .tab-pane {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
  }

  .tabpanel {
    float: left;
    width: 100%;
  }

  .panel-description {
    ul {
      list-style-type: none;
      list-style-position: inside;
      padding-left: 0px !important; //bootstrap override
    }

    li:before {
      content: '\25CF'; //unicode Black Circle
      padding-left: 1px;
      padding-right: 5px;
    }
  }

  .panel-id {
    white-space: nowrap;
    padding-bottom: 10px;
    position: relative;
    top: -3px;
  }

  .alaska-only {
    opacity: 0.25;
    //background-color: $warning-color!important;
  }
  //Alan's layout request implementation
  .panel-table {
    display: table;
    width: 100%;
    border-collapse: separate;
    border-spacing: 15px;
  }

  .panel-tr {
    display: table-row;
  }

  .panel-td {
		background-color:#efefef;
    display: table-cell;
    width: 24.9%;
    padding: 10px;
    border: 1px solid black;
    box-shadow: 3px 4px 5px 0px rgba(0,0,0,0.44);
  }

   .nav:after, .nav:before {
     display:inline-block!important;
   }
   .nav {
    display: inline-block!important;
    width: 100%!important;
   }

  @media (max-width: 600px) {
    .panel-table {
      display: block;
      width: inherit;
    }

    .panel-tr {
      display: block;
      width: inherit;
    }

    .panel-td {
      display: block;
      width: inherit;
    }
  }
}
