app-trims {
	.routecontent {
		display:flex;
		flex-direction: column; /* this makes row */

		.acc-trim-card {
			width: 150px;
			//white-space: nowrap;
			//line-height: 1.2em;
		}
	}

}

.trimcat {
	width: -webkit-fill-available;
}

hr {
	margin-top: 0;
}

.td-top {
	vertical-align: top;
}
.trimResult {
	display: flex;
	flex-direction: row; 
}
.trim-desc {
	vertical-align: top;
	text-align: right;
	font-weight: bold !important;
	padding-right: 5px;
}

.trim-product-image {
	height: 100px;
	width: 100px;
	border: 1px solid gray;
	margin-right: 10px;
	cursor: zoom-in;

	&:hover {
		box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba($brand-color, 0.6) !important;
	}
}

.trim-result-table {
	display: inline-block;
	width: 100%;
	//td{border:1px solid blue;}

	.t-desc {
		width: 30%;
	}

	.t-gauge {
		width: 5%;
	}

	.t-ctwt {
		width: 5%;
	}

	.t-grade {
		width: 5%;
	}

	.t-color {
		width: 20%;
	}

	.t-compat {
		width: auto;
	}
}
