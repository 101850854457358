app-orders {
	.body-cards {
		padding-right: 10px;
		width: 100%;

		display: block;
	}
	.tab-content {

		border: 1px solid #ddd;
		border-top: 0;
		height: calc(100% - 75px);

	}
	.orders-content {
		padding: 20px;
	}
	.tab-pane{
		height: 100%;
	}
	iframe {
		width: 100%;
		height: 100%;
		position: relative;
		top: -11px;
	}

	.so-detail-label {
		white-space: nowrap;
		font-weight: bold;
		text-align: right;
		padding-right: 5px;
		vertical-align: top;
	}


	.order-number-po {
		border-radius: 5px;
		background-color: mix(white, $brand-color, 60) !important;
	}

	#order-items {
		margin-top: 10px;
		margin-bottom: 10px;

		table thead tr {
			background-color: mix(white, $brand-color, 60) !important;
		}
	}
.noPdf{
	text-align: center;
	margin-top: 10px;
}
	.order-cutlist-item {
		display: block;
		font-family: Courier New, Courier, monospace;
		padding-left: 10px;

		span {
			width: 30px;
			display: inline-block;
			text-align: right;
		}
	}

	.invalid-reason {
		font-style: italic;
		opacity: 0.66;
		font-size: 0.8em;
	}

	#order-status-table {
		width: 100%;

		td {
			vertical-align: top;
		}
	}


	.order-headers {
		table {
			margin-top: 5px;
			margin-bottom: 5px;
		}

		td {
			vertical-align: top;
		}
	}

	.order-header-cols > tbody > tr > td {
		vertical-align:top!important;
		padding: 3px 5px;
	}
/*
	.order-header-cols >tbody>tr>td>table>tr {
		border-top: 1px solid #efefef;
	}
*/
@media only screen and (max-width: 1200px) {
	.order-header-cols {
		display: block;
	}
	.order-header-cols > tbody > tr {
		display: block;
		
	}
	.order-header-cols > tbody > tr > td {
		display: block;
		margin-bottom:10px;
	}
	 
}

		/*
		ul.nav-tabs li i.fa {
			margin-right: 5px;
		}
	
		.tab-content {
			border-top: 0px solid red;
			border-left: 1px solid #ddd;
			border-right: 1px solid #ddd;
			border-bottom: 1px solid #ddd;
			border-bottom-left-radius: 4px;
			border-bottom-right-radius: 4px;
			position: absolute;
			top: 50px;
			left: 10px;
			right: 10px;
			bottom: 12px;
		}
	
		#pdf-iframe {
			position: absolute;
			top: 1px;
			left: 0px;
			right: 0px;
			bottom: 0px;
		}
	
		#order-details {
			padding: 10px;
			overflow-y: auto;
			position: absolute;
			top: 1px;
			left: 0px;
			right: 0px;
			bottom: 0px;
		}
	

	
		
	
		overflow:hidden;
	
		.orders-list {
			position:absolute;
			top: 0px;
			bottom:0px;
			width: 200px;
			background-color:white;
			overflow-y:auto;
			.active {
				color: $brand-color
			}
		}
		.order-detail {
			position:absolute;
			top: 0px;
			bottom:0px;
			left:200px;
			width: 1080px;
			background-color:white;
			overflow:hidden;
			
		}
		pre {margin:0}
		
		#order-pdf,#order-details{
			position:absolute;
			top:52px;
			bottom:0px;
			left:0px;
			right:0px;
			
		}
		#order-details {
			//border:1px solid red;
			overflow:auto;
			
		}
	
		
		/*
		.tab-pane {
			height:100%;
		}
		.tab-content {
			width:100%;
			overflow-y:auto;
			position:absolute;
			left:10px;
			right:40px;
			top:45px;
			bottom:10px;
			
			iframe.order-pdf {
				border:1px solid yellow;
			
				width:100%;
				height:100%;
			}
		}
		*/
			/*iframe.order-pdf {
				border:0;
				
				position:absolute;
				top: 40px;
				bottom:40px;
				left:40px;
				right:40px;
			}
		
		
	
	
	
		
		*/


		
	.order-list-item {
		border-bottom: 1px solid #ccc;
		cursor: pointer;
		padding-left: 5px;
		position: relative;
		left: -7px;
	
		&:hover {
			background-color: $brand-color-light;
			color: black;
		}
	}
	
	.order-list-item.active {
		background-color: $brand-color;
		color: white;
	}
	
	.order-list-item.active:after {
		left: 100%;
		top: 50%;
		border: solid transparent;
		content: " ";
		height: 0;
		width: 0;
		position: absolute;
		pointer-events: none;
		border-color: rgba(0, 102, 94, 0);
		border-left-color: #00665E;
		border-width: 7px;
		margin-top: -7px;
	}
}