app-login{
	width: 100%;
	height: 100%;

	background-clip: border-box;
	background-color: rgb(255, 255, 255);
	background-image: url(/assets/images/wp-light.jpg);
	background-origin: padding-box;
	background-position-x: 50%;
	background-position-y: 50%;
	background-repeat: no-repeat;
	background-size: cover;
	display: flex;
	flex-direction: column;

	position: fixed;
	top:0;
	left:0;
	right:0;
	bottom:0;


	.flex-container {
		height: 100%;
		padding: 0;
		margin: 0;
		display: flex;
		align-items: center;
		justify-content: center;

	}
	.row {
		width: auto;
		max-width: 500px;
		box-shadow: rgba(0, 0, 0, 0.439216) 3px 4px 5px 0px;
		border: 1px solid black;
		background-color:white;
		flex-direction: column; 
		padding: 15px;
	}
	.flex-item {
		padding: 5px;
		text-align: center;
	}

	.loginBtn{
		width: 350px;
		border: 1px solid #00665E;
		outline: 1px solid white;
		border-radius: 5px;
		font-size: 1.2em;
		line-height: 1em;
		height: 2em;
	}

	.logonBox {
		display: inline-block;
		display: block;
		margin: 0 auto!important;
		max-width: 500px;
		background-color: #eee;
		border: 1px solid black;
		padding: 25px;
		margin: 20px;
		box-shadow: rgba(0, 0, 0, 0.439216) 3px 4px 5px 0px;
	
	
	}
	
	.headerImg {
		display:block;
		width: 80%;
		margin: 0 auto!important;
	}
	
	.authIcon{
		height: 2em;
		width: 2em;
		margin-top:.25em;
	}
}
