.configure-content, .cutlist-component{
  
  padding:10px;

  .panel-image {
    border: 1px solid #ccc;
    //float:left;
    margin-right:10px;
    margin-bottom:10px;
    width:130px;height:130px;
  }



  .panel-title {
    font-size: 1.5em;
    font-weight: bold;
  }
  .panel-id{
    margin-bottom:10px;
  }
  
  .color-menu-image{
      position:relative;
    top:-1px;
    left:-10px;
    }
  button  .color-menu-image{
    margin-left:8px;
  }
  .chipWarning{
    color:#aaa;
    font-size:0.75em;
    padding: 0px;
    font-style:italic;
    margin-bottom:10px;
  }
  .form-group{
    white-space:nowrap;
  }



    
  /*CSS3 extras*/
  .ng-tooltip span
  {
      border-radius:4px;
      box-shadow: 5px 5px 8px #CCC;
      z-index:100;
  }
  .input-group .form-control {
    z-index:1!important;
  }

  table.cutlist {
    background-color:white;
    //border:1px solid #ddd;

    td,th{
        white-space:nowrap;
    }
    
    input.form-control {
      width:50px!important;
      display:inline;
      text-align:center;    
      padding-left:0;    
      padding-right:0;
      font-family:Courier New, Courier, monospace;
      font-size:1.2em;
      font-weight:bold;
    }
    .fractions{
      font-family:Courier New, Courier, monospace;
      font-size:1.2em;
      font-weight:bold;
    }
    .input-feet{
      background-image:url(/assets/images/feet.png);
      background-position:right top;
      background-repeat:no-repeat;
      border-right:0;
    }
    .input-inch{
      background-image:url(/assets/images/inches.png);
      background-position:right top;
      background-repeat:no-repeat;
    }
    input.inches {
      width:40px!important;
    }
    input.totinches {
      width:80px!important;
    }
    input.qty{
      background-color:#ffe!important
    }
    select.form-control{
      padding-left:0;    
      padding-right:0;
      option {
        background-color:lightgoldenrodyellow;
        font-weight:bold;
      }
    }
    td.hinttd{
      border-left:0px solid white;
      border-bottom:0px solid white;
      background-color:white;
    }
    tr.hintrow{
      border-left:0!important;
      border-right:0!important;
    }
    .btn-cell {
      border:1px solid #aaa
    }
  }
  
  table.hints{
    opacity:0.5;
    font-size:0.8em;
    width:100%;
    tr {border:0}
    td{border:1px solid white;text-align:right;padding-left:3px;}
    td.cmd {text-align:left;font-weight:bold}
    &:hover{
      opacity:1;
    }
  }

  table.hints2{
    opacity:0.5;
    font-size:0.8em;
    width:100%;
    tr {border:0}
    td{border:1px solid white;text-align:center;}
    td.cmd {font-weight:bold}
    tr.legend td span{border-bottom:1px solid #ccc;font-weight:bold;padding:0 3px}
    &:hover{
      opacity:1;
    }
  }
  table.cutlist>thead>tr, table.cutlist>tbody>tr{
    border-top:1px solid #aaa;
    border-left:1px solid #aaa;
    border-right:1px solid #aaa;
    border-bottom:0;
}
  table.cutlist>thead>tr>th {
    text-align:center;
    padding:0;
    //border:1px solid #aaa;
    background-color:#ccc;
    font-stretch:condensed;
  }
  table.cutlist>tbody>tr>td {
    text-align:center;
    padding:3px;
    //border:1px solid #aaa;
    white-space:nowrap;
    
  }

  table.cutlist>tbody>tr.cuts{
    border-bottom:0!important;
  }
  table.cutlist>tbody>tr.cuts>td {
    border-bottom:0!important;
  }

  table.cutlist>tbody>tr.error-row {
    border-top:0;
  }
  table.cutlist>tbody>tr.error-row>td {
    border-top:0;
    color:red;
  }
  

  table.cutlist>tbody>tr>td.first-col {
    padding:0;
  }
   table.cutlist >tbody > tr.even{
      background-color:#eeeeee;
      .first-col{
        background-color:#cccccc
      }
    }
    table.cutlist >tbody > tr.odd{
      background-color:white;
      .first-col{
        background-color:#eeeeee
      }
    }

   table.line-ctrl{
     margin:0;
     padding:0;
   }
  table.line-ctrl>tbody>tr>td{
    margin:0;
    padding:0;
    text-align:center;
    width:10px;
    height:4px!important;
  }

  .updown{
    line-height:1px;
    img{
      opacity:0.5;
      cursor:pointer;
      &:hover{
        opacity:1;
        background-color:#aaa;
      }
    }
  }

  img.leftright{
    opacity:0.5;
      cursor:pointer;
      &:hover{
        opacity:1;
        background-color:#aaa;
      }
  }

  .hide-updown, .hide-leftright{
    visibility:hidden;
    pointer-events:none;
  }


  img.hide-leftright{
    visibility:hidden;
    pointer-events:none;
  }

  .text-left{
    color:black;
    text-align:left;
  }
	.radio-btn{
		padding-left:3px;
		line-height: 1em;
		vertical-align: middle;
		white-space: nowrap;
	}
	.radio-btn input {
		display: none;
	}



  .pack-td{
      font-family:Arial;
      font-stretch:condensed;
    vertical-align:top;
    padding-right:10px;
    .btn-group{
      font-stretch:condensed;
    }
    
  }

  th.cutlist-header{
      font-family:Arial;
      font-stretch:condensed;
  }

  td.cutlist-cell {
      white-space:nowrap;
            font-family:Arial;
      font-stretch:condensed;
  }

}
  .cutlist-total-table{
    float:right;
    margin: 3px 0;
    td{
      text-align:right;
      padding:0 5px;
      font-size:1.2em;
      font-family:Arial;
      font-stretch:condensed;
    }
    tr.sub-total{
      border-top:1px solid #ccc;
    }
    b {
      font-stretch:condensed
    }
  }