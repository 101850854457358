//app-tabset {
	.nav-tabs {
		display: flex;
		flex-direction: column;  /* and this makes rows */
		margin-bottom:-10px;
	}
	.nav-item {
		display:inline-block;
		margin-right: 5px;
		flex-wrap: wrap;
		a.nav-link.active {
			display:flex;
			background-color: white;
			color:#00665E;
			text-decoration: underline;
			border-bottom: 1px solid white;
		}
		a.nav-link {
			display:flex;
			background-color: #dfdfdf;
			color:#00665E;
			text-decoration: underline;
			border-bottom: 1px solid #dfdfdf;
		}
		
	}
//}